import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Accordion, Button, Form, FormGroup, Message } from 'semantic-ui-react'
import {
    Controller,
    FieldErrors,
    SubmitErrorHandler,
    useFieldArray,
    useForm,
} from 'react-hook-form'
import PowerPopup from '../../../common/ui/PowerPopup'
import useAddresses, { asAddressDescription } from '../../../hooks/useAddresses'
import { AddressCreate } from '../../../models/Address'
import TextInputForm from '../../../common/core/form/TextInputForm'
import PowerSelect from '../../../common/core/form/PowerSelect'
import { useCountriesForDropdown } from '../../../hooks/useCountries'
import { ContractType } from '../../../models/Contract'
import { contractsForDropdown } from '../../../hooks/useCompanies'

export type ContractForm = {
    type: ContractType
    paymentDueDays: number
}

interface _Props extends WithTranslation {
    isOpen: boolean
    onSave(
        name: string,
        email: string,
        address?: AddressCreate,
        addressId?: number,
        contracts?: ContractForm[],
        tin?: string,
        note?: string,
        bankAccountNumber?: string
    ): void
    onClose(): void
}

interface CreateClientForm {
    name: string
    tin?: string
    addressId?: string
    address1: string
    address2?: string
    city: string
    zip: string
    state?: string
    countryCode: string
    phone?: string
    email: string
    bankAccountNumber?: string
    note?: string
    contracts?: ContractForm[]
}

const AddNewClient: React.VFC<_Props> = (props) => {
    const { t, isOpen, onSave, onClose } = props

    const [isUseExistingAddress, setIsUsingExistingAddress] =
        React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const contractsDropdown = contractsForDropdown(t)

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<CreateClientForm>()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'contracts',
    })

    const addresses = useAddresses()
    const countriesDropdownOptions = useCountriesForDropdown()

    const onUseExisting = () => {
        setIsUsingExistingAddress(!isUseExistingAddress)
    }

    const addNewContract = () => {
        append({
            type: ContractType.SOW,
            paymentDueDays: 15,
        })
    }

    const onItemDelete = (index: number) => {
        remove(index)
    }

    const onSubmit = async (data: CreateClientForm) => {
        setIsLoading(true)
        const addressCreate: AddressCreate = {
            ...data,
        }

        const companyName = [data.name].join(' ')
        const contracts = data.contracts?.map((item: ContractForm) => {
            return {
                type: item.type,
                paymentDueDays: Number(item.paymentDueDays),
            }
        })

        if (data)
            await onSave(
                companyName,
                data.email,
                !isUseExistingAddress ? addressCreate : undefined,
                isUseExistingAddress ? Number(data.addressId) : undefined,
                contracts,
                data.tin,
                data.note,
                data.bankAccountNumber
            )
        setIsLoading(false)
    }

    const onError: SubmitErrorHandler<CreateClientForm> = async (
        data: FieldErrors<CreateClientForm>
    ) => {}

    return (
        <PowerPopup
            title={t('strings.new_client')}
            onClose={onClose}
            isOpen={isOpen}
            isLoading={isLoading}
            actions={
                <Button submit form='createClientForm' primary>
                    {t('strings.create_new')}
                </Button>
            }
        >
            <Form
                id='createClientForm'
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <Form.Group>
                    <TextInputForm
                        errors={errors}
                        required
                        name='name'
                        label={t('noun.name')}
                        width={8}
                        register={register}
                    />
                    <TextInputForm
                        errors={errors}
                        register={register}
                        name='tin'
                        label={t('strings.tax_number')}
                        width={5}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Checkbox
                        label={t('strings.use_existing_addr')}
                        checked={isUseExistingAddress}
                        onChange={onUseExisting}
                    ></Form.Checkbox>
                </Form.Group>
                {!isUseExistingAddress && (
                    <>
                        <Form.Group>
                            <TextInputForm
                                register={register}
                                name='address1'
                                width={7}
                                label={t('noun.address')}
                                required={!isUseExistingAddress}
                                errors={errors}
                            />
                            <TextInputForm
                                register={register}
                                name='city'
                                required={!isUseExistingAddress}
                                width={5}
                                errors={errors}
                                label={t('noun.city')}
                            />
                            <TextInputForm
                                register={register}
                                name='zip'
                                required={!isUseExistingAddress}
                                width={4}
                                errors={errors}
                                label={t('noun.zip')}
                            />
                        </Form.Group>

                        <Form.Group>
                            <TextInputForm
                                register={register}
                                width={7}
                                name='address2'
                                errors={errors}
                                label={t('strings.address2')}
                            />
                            <TextInputForm
                                register={register}
                                name='state'
                                width={5}
                                errors={errors}
                                label={t('noun.state')}
                            />
                        </Form.Group>
                        <Form.Group>
                            <PowerSelect
                                name='countryCode'
                                control={control}
                                required
                                fluid
                                width='7'
                                label={t('noun.country')}
                                options={countriesDropdownOptions}
                                errors={errors}
                            ></PowerSelect>
                        </Form.Group>
                    </>
                )}
                {isUseExistingAddress && (
                    <Form.Group>
                        <PowerSelect
                            control={control}
                            errors={errors}
                            width='7'
                            name='addressId'
                            label={t('noun.address')}
                            required
                            fluid
                            options={addresses.map((item) => {
                                return {
                                    key: item.id,
                                    text: asAddressDescription(item),
                                    value: item.id,
                                }
                            })}
                        ></PowerSelect>
                    </Form.Group>
                )}
                <Form.Group>
                    <TextInputForm
                        errors={errors}
                        width={7}
                        label={t('noun.contact')}
                        register={register}
                        name='phone'
                    />
                    <TextInputForm
                        errors={errors}
                        width={7}
                        required
                        label={t('strings.email_address')}
                        register={register}
                        name='email'
                    />
                    <TextInputForm
                        errors={errors}
                        width={7}
                        label={t('noun.iban')}
                        register={register}
                        name='bankAccountNumber'
                    />
                </Form.Group>
                <Accordion
                    panels={[
                        {
                            key: 'govno',
                            title: t('noun.contracts'),
                            content: {
                                content: (
                                    <Message>
                                        <Button
                                            type='button'
                                            style={{ margin: '0.5rem 0' }}
                                            primary
                                            onClick={() => addNewContract()}
                                        >
                                            {t('strings.add_item')}
                                        </Button>
                                        {fields.map(
                                            (item: ContractForm, index) => {
                                                const itemErrors =
                                                    errors.contracts?.[index]
                                                return (
                                                    <FormGroup key={index}>
                                                        <PowerSelect
                                                            name={`contracts.${index}.type`}
                                                            control={control}
                                                            fluid
                                                            width='7'
                                                            label={t(
                                                                'noun.contract'
                                                            )}
                                                            options={
                                                                contractsDropdown
                                                            }
                                                            errors={errors}
                                                        ></PowerSelect>
                                                        <TextInputForm
                                                            errors={itemErrors}
                                                            width={7}
                                                            type='number'
                                                            label={t(
                                                                'strings.delivery_date'
                                                            )}
                                                            register={register}
                                                            name={`contracts.${index}.paymentDueDays`}
                                                        ></TextInputForm>
                                                        <Button
                                                            color='red'
                                                            type='button'
                                                            onClick={() =>
                                                                onItemDelete(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Delete
                                                        </Button>
                                                    </FormGroup>
                                                )
                                            }
                                        )}
                                    </Message>
                                ),
                            },
                        },
                    ]}
                />
                <Controller
                    control={control}
                    name='note'
                    render={({ field }) => {
                        return (
                            <Form.TextArea
                                label='Note'
                                value={field.value}
                                placeholder='Write some reminder or whatnot...'
                                onChange={(evt, data) =>
                                    field.onChange(data.value)
                                }
                            ></Form.TextArea>
                        )
                    }}
                ></Controller>
            </Form>
        </PowerPopup>
    )
}

export default withTranslation()(AddNewClient)
