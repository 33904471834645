import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

interface Props {
    title: string
    href: string
}

const CBButton: React.VFC<Props> = (props: Props) => {
    const { href, title } = props
    return (
        <Button as={Link} to={href} primary>
            {title}
        </Button>
    )
}

export default CBButton
