import { ApiResponse } from '../models/api/ApiResponse'
import { LoginReponseDto } from '../models/LoginDto'

import EnvSettings from '../models/settings/EnvSettings'
import configService from './configService'

class LoginService {
    readonly config: EnvSettings

    constructor(config: EnvSettings) {
        this.config = config
    }

    async login(
        username: string,
        password: string
    ): Promise<ApiResponse<LoginReponseDto>> {
        const body = {
            username,
            password,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        }

        const response = await fetch(
            this.config.apiRoot + `api/v1/login`,
            requestOptions
        )

        const resultJson = await response.json()

        if (response.ok) {
            return resultJson
        } else {
            throw new Error(resultJson['errors'][0].reason)
        }
    }
}

export default new LoginService(configService.settings)
