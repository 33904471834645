import React from 'react'
import './Screens.scss'

interface _Props extends React.PropsWithChildren<any> {
    title: any
    right?: any
    children: any
}

const Button = (props: _Props) => {
    if (!props.right) {
        return <></>
    } else {
        return <div className='screen-right-content'>{props.right}</div>
    }
}

const Screen: React.FC<_Props> = (props: _Props) => {
    return (
        <div className='screen'>
            <div className='screen-header'>
                <h1 className='screen-title'>{props.title}</h1>
                {Button(props)}
            </div>
            <hr className='screen-divisor' />
            <div className='screen-content'>{props.children}</div>
        </div>
    )
}

export default Screen
