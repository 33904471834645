import React, { useState } from 'react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { DateTime } from 'luxon'
import { Grid, GridRow, Icon } from 'semantic-ui-react'

const NEW_YEAR = DateTime.now()
    .set({
        day: 1,
        month: 1,
        hour: 0,
        minute: 0,
        second: 0,
    })
    .minus({ day: 1 })

const LAST_DATE = DateTime.now().set({
    day: 31,
    month: 12,
})

const caluclateJoppdNumber = (date: Date): number => {
    let lux = DateTime.fromJSDate(date)
    return 23000 + lux.ordinal
}

const JoppdCalc: React.FC = () => {
    const [currentDate, setDate] = useState<Date>(DateTime.now().toJSDate())
    const [joppdNumber, setJoppdNumber] = useState(
        caluclateJoppdNumber(currentDate)
    )

    return (
        <>
            <Grid>
                <GridRow>
                    <div>Date</div>
                </GridRow>
                <GridRow>
                    <SemanticDatepicker
                        value={currentDate}
                        onChange={(e, d) => {
                            let date = d?.value!
                            if (date) {
                                setDate(date)
                                setJoppdNumber(caluclateJoppdNumber(date))
                            } else {
                                setDate(null)
                            }
                        }}
                        showToday
                        datePickerOnly
                        minDate={NEW_YEAR.toJSDate()}
                        maxDate={LAST_DATE.toJSDate()}
                        clearable={false}
                        icon={
                            <Icon
                                size='large'
                                name='calendar alternate outline'
                            ></Icon>
                        }
                    ></SemanticDatepicker>
                </GridRow>
                <GridRow>
                    <div>
                        JOPPD Number: <strong>{joppdNumber}</strong>
                    </div>
                </GridRow>
            </Grid>
        </>
    )
}

export default JoppdCalc
