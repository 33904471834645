import React from 'react'

import LineItems from './lineItems/lineItems'
import InvoiceSummary from './payTotal/InvoiceSummary'

interface Props {
    content: any
}

const InvoiceContent: React.FC<Props> = (props) => {
    const { lineItems, columnTitles } = props.content

    return (
        <div>
            <div className='tablicica'>
                <LineItems lineItems={lineItems} columnTitles={columnTitles} />
                <InvoiceSummary
                    content={props.content}
                    invoiceCurrency={props.content.invoiceCurrency}
                />
            </div>
        </div>
    )
}
export default InvoiceContent
