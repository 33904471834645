import React from 'react'
import { Address } from '../../models/Address'
import { Client } from '../../models/Client'
import PowerGrid from './powerGrid/PowerGrid'
import { PowerGridAction } from './powerGrid/PowerGridConfig'

interface Props {
    clients: Client[]
    deleteAction?(adress: Client): any
    editAction?(client: Client): any
}

const ClientsTable: React.VFC<Props> = (props) => {
    const { clients, deleteAction, editAction } = props

    const config = {
        dataSource: clients,
        key: (item: any) => item.id,
        columns: [
            { field: 'id', title: 'Id' },
            { field: 'name', title: 'Name', localizedTitle: 'noun.name' },
            {
                field: 'address',
                title: 'Address',
                localizedTitle: 'noun.address',
                formatter: (e: Address) => {
                    const { address1, zip, city } = e
                    return `${address1}, ${zip} ${city}`
                },
            },
            {
                field: 'address',
                key: 'country',
                title: 'Country',
                localizedTitle: 'noun.country',
                formatter: (e: Address) => {
                    return e.country
                },
            },
            {
                field: 'email',
                title: 'Email',
                localizedTitle: 'noun.contact',
            },
            {
                field: 'pin',
                title: 'Pin',
                localizedTitle: 'strings.vat_number',
            },
        ],
        actions: [
            {
                actionType: PowerGridAction.Edit,
                onClick: (item: Client) => {
                    editAction?.(item)
                },
            },
            {
                actionType: PowerGridAction.Delete,
                onClick: (item: Client) => {
                    deleteAction?.(item)
                },
            },
        ],
    }

    return <PowerGrid config={config}></PowerGrid>
}
export default ClientsTable
