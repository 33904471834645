import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './common/app/App'
import './common/core/i18n/setupTranslations'
import i18next from 'i18next'
import './index.css'
import './utilities.scss'
import { I18nextProvider } from 'react-i18next'

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
