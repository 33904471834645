import React from 'react'
import { useLineItemsSwr } from '../../hooks/useLineItems'
import AddEditLineItem from '../../common/app/fragments/AddEditNewLineItem'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Button } from 'semantic-ui-react'
import lineItemService from '../../services/LineItemService'
import { CBLineItem, CBLineItemCreate } from '../../models/CBLineItem'
import { useToast } from '../../common/ui/toast/ToastProvider'
import { ToastType } from '../../common/ui/toast/ToastItem'
import LineItemRenderer from '../../common/app/fragments/LineItemRenderer'

const LineItemEditor: React.VFC<WithTranslation> = (props) => {
    const { t } = props
    const { data: lineItems, mutate } = useLineItemsSwr()
    const [isAddNewOpened, setAddNewOpened] = React.useState(false)
    const [selectedExistingItem, setSelectedExistingItem] =
        React.useState<CBLineItem>()
    const toasts = useToast()

    const sendCreate = (it: CBLineItemCreate) => {
        lineItemService
            .addNew(it.nameHr, it.unitPrice, it.currency, it.unit, it.nameEn)
            .then((it) => {
                mutate()
                setAddNewOpened(false)
                toasts.showMessage({
                    title: 'Kill bills!',
                    description: 'New Line Item created:' + it.data.id,
                    type: ToastType.SUCCESS,
                })
            })
            .catch((err: Error) => toasts.showError(err.message))
    }

    const sendEdit = (it: CBLineItemCreate, existingId: number) => {
        lineItemService
            .edit(
                existingId,
                it.nameHr,
                it.unitPrice,
                it.currency,
                it.unit,
                it.nameEn
            )
            .then((it) => {
                mutate()
                setAddNewOpened(false)
                setSelectedExistingItem(undefined)
                toasts.showMessage({
                    title: 'Kill bills!',
                    description: 'Line item updated! ID:' + it.data.id,
                    type: ToastType.SUCCESS,
                })
            })
            .catch((err: Error) => toasts.showError(err.message))
    }

    const onDelete = (id: number) => {
        lineItemService
            .delete(id)
            .then((it) => {
                mutate()
                setAddNewOpened(false)
                toasts.showMessage({
                    title: 'Kill bills!',
                    description: 'Deleted ID: ' + id,
                    type: ToastType.SUCCESS,
                })
            })
            .catch((err: Error) => toasts.showError(err.message))
    }

    return (
        <>
            <Button
                primary
                onClick={() => {
                    setAddNewOpened(true)
                }}
            >
                {t('strings.new_line_item')}
            </Button>
            {lineItems.map((e) => (
                <LineItemRenderer
                    key={e.id}
                    item={e}
                    onDelete={onDelete}
                    onEdit={(e) => {
                        setSelectedExistingItem(e)
                        setAddNewOpened(true)
                    }}
                />
            ))}
            {isAddNewOpened && (
                <AddEditLineItem
                    isOpen={isAddNewOpened}
                    existingItem={selectedExistingItem}
                    onClose={() => {
                        setSelectedExistingItem(undefined)
                        setAddNewOpened(false)
                    }}
                    onSaveOrEdit={(dto, id) => {
                        if (id) {
                            sendEdit(dto, id)
                        } else {
                            sendCreate(dto)
                        }
                    }}
                ></AddEditLineItem>
            )}
        </>
    )
}

export default withTranslation()(LineItemEditor)
