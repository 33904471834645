import config from '../config.json'
import EnvSettings from '../models/settings/EnvSettings'

class ConfigService {
    readonly settings: EnvSettings

    constructor(json: EnvSettings) {
        this.settings = json
    }
}

export default new ConfigService(config)
