import React, { Component } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { LineItemUnit } from '../../../../../../../models/CBLineItem'

import './lineItems.scss'

interface Props extends WithTranslation {
    lineItems: any[]
    columnTitles: string[]
}

interface RendererProps extends WithTranslation {
    quantity: number
    lineItemUnit: LineItemUnit
}

const LineItemUnitRenderer: React.VFC<RendererProps> = (props) => {
    const { t, quantity, lineItemUnit } = props

    var key = 'countables.pc'
    switch (lineItemUnit) {
        case LineItemUnit.HOURLY:
            key = 'countables.hours'
            break
        default:
            break
    }

    return <>{t(key, { count: quantity })}</>
}

const LineItems: React.VFC<Props> = (props) => {
    const { lineItems, columnTitles } = props
    return (
        <table className='bills'>
            <thead>
                <tr className='tableHead'>
                    {columnTitles.map((it, index) => {
                        return <th key={index}>{it}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {lineItems.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td className='broj'>{index + 1}</td>
                            <td className='quantity'>
                                <LineItemUnitRenderer
                                    {...props}
                                    quantity={item.quantity}
                                    lineItemUnit={item.lineItemUnit}
                                ></LineItemUnitRenderer>
                            </td>
                            <td className='bill-name'>{item.name}</td>
                            <td className='unit-price'>{item.unitPrice}</td>
                            <td className='line-price'>{item.linePrice}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default withTranslation()(LineItems)
