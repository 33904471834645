import React from 'react'
import { TFunction } from 'react-i18next'
import { DropdownItemProps } from 'semantic-ui-react'
import useSWR from 'swr'
import { Client } from '../models/Client'
import { ContractType } from '../models/Contract'
import clientApiService from '../services/clientApiService'

export default function useCompanies() {
    const { data, mutate } = useSWR('companies', clientApiService.getAll)

    return React.useMemo(() => {
        return {
            data: data?.data || [],
            mutate,
        }
    }, [data, mutate])
}

export function useCompaniesForDropdown(): DropdownItemProps[] {
    const { data } = useCompanies()

    return data.map((item) => {
        return {
            key: item.id,
            text: item.name,
            value: item.id,
        }
    })
}

export function contractsForDropdown(t: TFunction): DropdownItemProps[] {
    return [
        {
            key: ContractType.SOW,
            value: ContractType.SOW,
            text: t('strings.sow_contract'),
        },
        {
            key: ContractType.RENT,
            value: ContractType.RENT,
            text: t('strings.rent_contract'),
        },
        {
            key: ContractType.SUBCONTRACT,
            value: ContractType.SUBCONTRACT,
            text: t('strings.subcontract'),
        },
    ]
}
