import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Grid, Header, Segment } from 'semantic-ui-react'
import { CBLineItem, LineItemUnit } from '../../../models/CBLineItem'

interface Props extends WithTranslation {
    item: CBLineItem
    onDelete?(id: number): void
    onEdit?(item: CBLineItem): void
}

const unitToText = (t: any, u: LineItemUnit): string => {
    switch (u) {
        case LineItemUnit.HOURLY:
            return t('strings.hourly_rate')
        case LineItemUnit.SINGLE:
            return t('noun.piece')
    }

    return ''
}

const LineItemRenderer: React.VFC<Props> = (props: Props) => {
    const { t, item, onDelete, onEdit } = props

    const { id, nameEn, nameHr, unitPrice, currency, unit } = item

    return (
        <Segment>
            <Grid columns={3} stretched>
                <Grid.Row>
                    <Grid.Column width={10}>
                        <Header as='h4'>{t('strings.croatian_name')}</Header>
                        <p>{nameHr}</p>
                        <Header as='h4'>{t('strings.english_name')}</Header>
                        <p>{nameEn || 'N/A'}</p>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Header as='h4'>{t('strings.unit_price')}</Header>
                        <p>
                            {unitPrice} {currency.symbol}
                        </p>
                        <Header as='h4'>{t('noun.unit')}</Header>
                        <p>{unitToText(t, unit)}</p>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Grid verticalAlign='middle'>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button
                                        fluid
                                        primary
                                        onClick={() => {
                                            onEdit?.(item)
                                        }}
                                    >
                                        {t('verb.edit')}
                                    </Button>
                                    <br />
                                    <Button
                                        fluid
                                        color='red'
                                        onClick={() => onDelete?.(id)}
                                    >
                                        {t('verb.delete')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default withTranslation()(LineItemRenderer)
