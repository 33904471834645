import React from 'react'
import {
    FieldErrors,
    SubmitErrorHandler,
    SubmitHandler,
    useForm,
} from 'react-hook-form'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Form } from 'semantic-ui-react'
import PowerSelect from '../../common/core/form/PowerSelect'
import TextInputForm from '../../common/core/form/TextInputForm'
import PowerPopup from '../../common/ui/PowerPopup'
import { useCountriesForDropdown } from '../../hooks/useCountries'
import { AddressCreate } from '../../models/Address'

interface Props extends WithTranslation {
    isOpen: boolean
    onSave(address: AddressCreate): void
    onClose(): void
}

interface FormFields {
    address1: string
    address2?: string
    city: string
    zip: string
    state?: string
    countryCode: string
}

const AddNewAddress: React.VFC<Props> = (props) => {
    const { t, isOpen, onSave, onClose: propsOnClose } = props

    const [isLoading, setIsLoading] = React.useState(false)
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormFields>()

    const onSubmit: SubmitHandler<FormFields> = async (deita: FormFields) => {
        setIsLoading(true)
        const addressCreate = {
            ...deita,
        }

        await onSave(addressCreate)

        setIsLoading(false)
    }
    const onError: SubmitErrorHandler<FormFields> = (
        errors: FieldErrors<FormFields>,
        e: any
    ) => {}

    const countriesDropdownOptions = useCountriesForDropdown()

    return (
        <PowerPopup
            title={t('strings.new_address')}
            onClose={propsOnClose}
            isOpen={isOpen}
            isLoading={isLoading}
            actions={
                <Button submit form='createAddressForm' primary>
                    {t('strings.create_new')}
                </Button>
            }
        >
            <Form
                id='createAddressForm'
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <Form.Group>
                    <TextInputForm
                        register={register}
                        name='address1'
                        width={7}
                        label={t('noun.address')}
                        required={true}
                        errors={errors}
                    />
                    <TextInputForm
                        register={register}
                        name='city'
                        required={true}
                        width={5}
                        errors={errors}
                        label={t('noun.city')}
                    />
                    <TextInputForm
                        register={register}
                        name='zip'
                        required={true}
                        width={4}
                        errors={errors}
                        label={t('noun.zip')}
                    />
                </Form.Group>

                <Form.Group>
                    <TextInputForm
                        register={register}
                        width={7}
                        name='address2'
                        errors={errors}
                        label={t('strings.address2')}
                    />
                    <TextInputForm
                        register={register}
                        name='state'
                        width={5}
                        errors={errors}
                        label={t('noun.state')}
                    />
                </Form.Group>
                <Form.Group>
                    <PowerSelect
                        name='countryCode'
                        control={control}
                        required
                        fluid
                        width='7'
                        label={t('noun.country')}
                        options={countriesDropdownOptions}
                        errors={errors}
                    ></PowerSelect>
                </Form.Group>
            </Form>
        </PowerPopup>
    )
}

export default withTranslation()(AddNewAddress)
