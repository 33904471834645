import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Accordion, Button, Form, FormGroup, Message } from 'semantic-ui-react'
import {
    Controller,
    FieldErrors,
    SubmitErrorHandler,
    useFieldArray,
    useForm,
} from 'react-hook-form'
import PowerPopup from '../../../common/ui/PowerPopup'
import useAddresses, { asAddressDescription } from '../../../hooks/useAddresses'
import TextInputForm from '../../../common/core/form/TextInputForm'
import PowerSelect from '../../../common/core/form/PowerSelect'
import { Contract, ContractType } from '../../../models/Contract'
import { Client } from '../../../models/Client'
import { contractsForDropdown } from '../../../hooks/useCompanies'

interface _Props extends WithTranslation {
    isOpen: boolean
    client: Client
    onSave(
        id: number,
        email: string,
        contracts?: any[],
        pin?: string,
        note?: string,
        bankAccountNumber?: string
    ): void
    onClose(): void
}

interface EditClientForm {
    name: string
    addressId: number
    phone?: string
    email: string
    bankAccountNumber?: string
    note?: string
    pin: string
    contracts?: Contract[]
}

const EditClient: React.VFC<_Props> = (props) => {
    const { t, isOpen, onSave, onClose, client } = props

    const [isLoading, setIsLoading] = React.useState(false)
    const contractsDropdown = contractsForDropdown(t)

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<EditClientForm>({
        defaultValues: {
            ...client,
            addressId: client.address.id,
        },
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'contracts',
    })

    const addresses = useAddresses()

    const addNewContract = () => {
        append({
            type: ContractType.SOW,
            paymentDueDays: 15,
        })
    }

    const onItemDelete = (index: number) => {
        remove(index)
    }

    const onSubmit = async (data: EditClientForm) => {
        setIsLoading(true)

        const contracts = data.contracts?.map((item: Contract) => {
            return {
                type: item.type,
                paymentDueDays: Number(item.paymentDueDays),
            }
        })

        if (data)
            await onSave(
                client.id,
                data.email,
                contracts,
                data.pin,
                data.note,
                data.bankAccountNumber
            )
        setIsLoading(false)
    }

    const onError: SubmitErrorHandler<EditClientForm> = async (
        data: FieldErrors<EditClientForm>
    ) => {}

    return (
        <PowerPopup
            title={t('strings.update_client')}
            onClose={onClose}
            isOpen={isOpen}
            isLoading={isLoading}
            actions={
                <Button submit form='updateClientForm' primary>
                    {t('strings.update')}
                </Button>
            }
        >
            <Form
                id='updateClientForm'
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <Form.Group>
                    <TextInputForm
                        errors={errors}
                        required
                        name='name'
                        label={t('noun.name')}
                        width={8}
                        disabled
                        register={register}
                    />
                    <TextInputForm
                        errors={errors}
                        register={register}
                        name='pin'
                        label={t('strings.tax_number')}
                        width={5}
                    />
                </Form.Group>
                <Form.Group>
                    <PowerSelect
                        control={control}
                        errors={errors}
                        width='7'
                        name='addressId'
                        label={t('noun.address')}
                        required
                        disabled
                        fluid
                        options={addresses.map((item) => {
                            return {
                                key: item.id,
                                text: asAddressDescription(item),
                                value: item.id,
                            }
                        })}
                    ></PowerSelect>
                </Form.Group>
                <Form.Group>
                    <TextInputForm
                        errors={errors}
                        width={7}
                        label={t('noun.contact')}
                        register={register}
                        name='phone'
                    />
                    <TextInputForm
                        errors={errors}
                        width={7}
                        required
                        label={t('strings.email_address')}
                        register={register}
                        name='email'
                    />
                    <TextInputForm
                        errors={errors}
                        width={7}
                        label={t('noun.iban')}
                        register={register}
                        name='bankAccountNumber'
                    />
                </Form.Group>
                <Accordion
                    panels={[
                        {
                            key: 'govno',
                            title: t('noun.contracts'),
                            content: {
                                content: (
                                    <Message>
                                        <Button
                                            type='button'
                                            style={{ margin: '0.5rem 0' }}
                                            primary
                                            onClick={() => addNewContract()}
                                        >
                                            {t('strings.add_item')}
                                        </Button>
                                        {fields.map((item: any, index) => {
                                            const itemErrors =
                                                errors.contracts?.[index]
                                            return (
                                                <FormGroup key={index}>
                                                    <PowerSelect
                                                        name={`contracts.${index}.type`}
                                                        control={control}
                                                        fluid
                                                        width='7'
                                                        label={t(
                                                            'noun.contract'
                                                        )}
                                                        options={
                                                            contractsDropdown
                                                        }
                                                        errors={errors}
                                                    ></PowerSelect>
                                                    <TextInputForm
                                                        errors={itemErrors}
                                                        width={7}
                                                        type='number'
                                                        label={t(
                                                            'strings.delivery_date'
                                                        )}
                                                        register={register}
                                                        name={`contracts.${index}.paymentDueDays`}
                                                    ></TextInputForm>
                                                    <Button
                                                        color='red'
                                                        type='button'
                                                        onClick={() =>
                                                            onItemDelete(index)
                                                        }
                                                    >
                                                        Delete
                                                    </Button>
                                                </FormGroup>
                                            )
                                        })}
                                    </Message>
                                ),
                            },
                        },
                    ]}
                />
                <Controller
                    control={control}
                    name='note'
                    render={({ field }) => {
                        return (
                            <Form.TextArea
                                label='Note'
                                value={field.value}
                                placeholder='Write some reminder or whatnot...'
                                onChange={(evt, data) =>
                                    field.onChange(data.value)
                                }
                            ></Form.TextArea>
                        )
                    }}
                ></Controller>
            </Form>
        </PowerPopup>
    )
}

export default withTranslation()(EditClient)
