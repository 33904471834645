import { AddressCreate } from '../models/Address'
import { ApiResponse } from '../models/api/ApiResponse'

import { Client } from '../models/Client'
import EnvSettings from '../models/settings/EnvSettings'
import configService from './configService'
import axios, { apiErrorParser } from './base/BaseApiService'

class ClientApiService {
    readonly config: EnvSettings

    constructor(config: EnvSettings) {
        this.config = config
    }

    async getAll(): Promise<ApiResponse<Client[]>> {
        try {
            const response = await axios.get('/api/v1/company')
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }

    async delete(id: number): Promise<void> {
        try {
            const response = await axios.delete(`/api/v1/company/${id}`)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }

    async edit(
        id: number,
        email: string,
        contracts?: any[],
        pin?: string,
        note?: string,
        bankAccountNumber?: string
    ): Promise<ApiResponse<Client>> {
        const dto = {
            pin: pin,
            email: email,
            note: note,
            bankAccountNumber: bankAccountNumber,
            contracts: contracts,
        }
        try {
            const response = await axios.put(`/api/v1/company/${id}`, dto)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }

    async addNew(
        name: string,
        email: string,
        address?: AddressCreate,
        pin?: string,
        addressId?: number,
        note?: string,
        contracts?: any[],
        bankAccountNumber?: string
    ): Promise<ApiResponse<Client>> {
        const dto = {
            name,
            email,
            pin,
            note,
            contracts,
            bankAccountNumber,
            addressId,
            address,
        }

        try {
            const response = await axios.post(`/api/v1/company`, dto)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }
}

export default new ClientApiService(configService.settings)
