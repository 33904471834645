import { CBCurrencyDto } from './CBCurrency'

export enum LineItemUnit {
    SINGLE = 'SINGLE',
    HOURLY = 'HOURLY',
    PIECE = 'PIECE',
}

export interface CBLineItem {
    id: number
    nameEn?: string
    nameHr: string
    unitPrice: number
    currency: CBCurrencyDto
    unit: LineItemUnit
}

export interface CBLineItemCreate {
    nameHr: string
    nameEn?: string | undefined | null
    unitPrice: number
    currency: string
    unit: LineItemUnit
}
