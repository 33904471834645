import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import './InvoiceSummary.scss'

interface Props extends WithTranslation {
    invoiceCurrency: string
    content: {
        subtotal: string
        total: string
        tax: string
        totalPrimary: string
        primaryCurrencyCode: string
        totalSecondary?: string
        euroFxRate?: string
    }
}

const InvoiceSummary: React.FC<Props> = (props) => {
    const { t, content, invoiceCurrency } = props

    return (
        <div className='invoice-summary-section'>
            <table className='totals-table'>
                <tbody>
                    <tr>
                        <td>{t('noun.subtotal')}</td>
                        <td>
                            <b>{content.subtotal}</b>
                        </td>
                    </tr>
                    <tr>
                        <td>{t('noun.vat')}</td>
                        <td>
                            <b>{content.tax}</b>
                        </td>
                    </tr>
                    <tr className='line'>
                        <td>{t('noun.total')}</td>
                        <td>
                            <b>{content.total}</b>
                        </td>
                    </tr>
                    {invoiceCurrency !== content.primaryCurrencyCode && (
                        <tr className='total-hrk top-2'>
                            <td>{`${t('noun.total')} ${
                                content.primaryCurrencyCode
                            }`}</td>
                            <td>
                                <b>{content.totalPrimary}</b>
                            </td>
                        </tr>
                    )}
                    {content.totalSecondary && (
                        <>
                            <tr className='eur-segment top'>
                                <td></td>
                                <td>
                                    <b>{content.totalSecondary}</b>
                                </td>
                            </tr>
                            <tr className='eur-segment'>
                                <td></td>
                                <td>({content.euroFxRate})</td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default withTranslation()(InvoiceSummary)
