import { PowerGridConfig } from './PowerGridConfig'
import './PowerGrid.scss'
import { useTranslation } from 'react-i18next'

import EditImage from '../../images/edit_icon.svg'
import DownloadImage from '../../images/download_icon.svg'
import DeleteImage from '../../images/trash-svgrepo-com.svg'
import { Table } from 'semantic-ui-react'

interface PowerGridProps {
    config: PowerGridConfig
}

const _mapHeaders = (config: PowerGridConfig) => {
    const { t } = useTranslation()

    var tableColumns = config.columns.map((e, idx) => {
        if (typeof e.localizedTitle === 'string') {
            return (
                <Table.HeaderCell key={e.key || e.field}>
                    {t(e.localizedTitle) || e.title}
                </Table.HeaderCell>
            )
        }

        return (
            <Table.HeaderCell key={e.key || e.field}>
                {e.title}
            </Table.HeaderCell>
        )
    })

    if (config.actions && config.actions.length > 0) {
        tableColumns.push(
            <Table.HeaderCell key={`actions_table_${tableColumns.length + 1}`}>
                {t('noun.action')}
            </Table.HeaderCell>
        )
    }

    return tableColumns
}

const _mapRow = (config: PowerGridConfig, item: any) => {
    var columns = config.columns.map((column, idx) => {
        const classes = `text-${column.textAlign || 'start'}`
        if (item.hasOwnProperty(column.field)) {
            if (typeof column.formatter === 'function') {
                return (
                    <Table.Cell
                        key={column.key || column.field}
                        className={classes}
                    >
                        {column.formatter(item[column.field])}
                    </Table.Cell>
                )
            } else {
                return (
                    <Table.Cell
                        key={column.key || column.field}
                        className={classes}
                    >
                        {item[column.field]}
                    </Table.Cell>
                )
            }
        } else {
            return (
                <Table.Cell className={classes}>
                    <span className='error'>
                        Invalid mapping for property:{' '}
                        <span className='subject'>{column.field}</span>
                    </span>
                </Table.Cell>
            )
        }
    })

    if (config.actions && config.actions.length > 0) {
        var actions = config.actions.map((e, idx) => {
            var klasa = ''
            var image = null
            switch (e.actionType) {
                case 1:
                    klasa = 'edit'
                    image = EditImage
                    break
                case 2:
                    klasa = 'download'
                    image = DownloadImage
                    break
                case 3:
                    klasa = 'delete'
                    image = DeleteImage
                    break
                default:
                    return ''
            }

            const onClick = () => {
                if (e.onClick) {
                    e.onClick(item)
                }
            }

            return (
                <img
                    key={idx}
                    src={image}
                    alt={klasa}
                    className={'grid-action-button ' + klasa}
                    onClick={onClick}
                />
            )
        })

        columns.push(
            <Table.Cell key='actions_table' className='actions-column'>
                {actions}
            </Table.Cell>
        )
    }

    return columns
}

const _mapRows = (config: PowerGridConfig) => {
    return config.dataSource.map((item, idx) => {
        return <Table.Row key={idx}>{_mapRow(config, item)}</Table.Row>
    })
}

const PowerGrid: React.FC<PowerGridProps> = (props: PowerGridProps) => {
    const { config } = props
    return (
        <Table celled className='main-table'>
            <Table.Header>
                <Table.Row>{_mapHeaders(config)}</Table.Row>
            </Table.Header>
            <Table.Body>{_mapRows(config)}</Table.Body>
        </Table>
    )
}

export default PowerGrid
