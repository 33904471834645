import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Form, Input, Label } from 'semantic-ui-react'
import { InvoiceLineItem } from './NewInvoiceModels'

interface Props extends WithTranslation {
    id: number
    items: InvoiceLineItem[]
}

const SubtotalRenderer: React.VFC<Props> = (props) => {
    const { t, id, items } = props

    const currentLineItem = items.find((e) => e.protoId === id)

    if (!currentLineItem) {
        return <></>
    }

    const price =
        currentLineItem.price *
        currentLineItem.quantity *
        (1 - (currentLineItem.discountPercent || 0) / 100)

    return (
        <Form.Field>
            <label>{t('noun.subtotal')}</label>
            <Form.Input labelPosition='right' readOnly value={price}>
                <input></input>
                <Label basic={true} content={currentLineItem.currency}></Label>
            </Form.Input>
        </Form.Field>
    )
}

export default withTranslation()(SubtotalRenderer)
