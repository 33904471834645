import React, { useEffect } from 'react'
import PowerGrid from './powerGrid/PowerGrid'

import './ClientsTable.scss'
import { PowerGridAction } from './powerGrid/PowerGridConfig'
import { withTranslation } from 'react-i18next'
import GeneratedInvoice from '../../pages/invoices/new-invoice/generated-invoice/PrintInvoice'
import { useInvoicesForList } from '../../hooks/useInvoices'
import LanguageContext from '../core/i18n/LanguageContext'
import invoiceService from '../../services/invoiceService'
import { useToast } from './toast/ToastProvider'
import { ToastType } from './toast/ToastItem'
import { useNavigate } from 'react-router-dom'
import InvoiceStateRenderer from '../app/fragments/InvoiceStateRenderer'
import { Button } from 'semantic-ui-react'

const YEAR_FACET_NAME = "year"

const InvoicesTable: React.FC = () => {

    const [selectedInvoiceId, setSelectedInvoiceId] = React.useState(null)
    const [selectedYear, setSelectedYear] = React.useState<string>()
    const [invoices, mutate] = useInvoicesForList(selectedYear)
    const toast = useToast()
    let navigate = useNavigate()

    const deleteInvoice = async (invoiceId: number | string) => {
        invoiceService
            .delete(invoiceId)
            .then((e) =>
                toast.showMessage({
                    title: 'Kill bills',
                    description: `Invoice ${invoiceId} deleted`,
                    type: ToastType.SUCCESS,
                })
            )
            .catch((r: Error) => toast.showError(r.message))
            .finally(mutate)
    }

    const setEditableInvoiceId = async (invoiceId: number | string) => {
        navigate(`/Invoices/${invoiceId}`)
    }

    useEffect(() => {
        mutate()
    }, [selectedYear])

    const onKeySelect = (key: string) => {
        if (key !== selectedYear) {
            setSelectedYear(key)
        } else {
            setSelectedYear(undefined)
        }
    }

    // currently hardcoded
    const years = invoices?.filters
                .filter(it => it.key === YEAR_FACET_NAME)
                .flatMap(it => it.facets) || []

    const config = {
        dataSource: invoices?.data || [],
        key: (key: any) => key.id,
        columns: [
            {
                field: 'invoiceNumber',
                localizedTitle: 'strings.invoice_number',
                // formatter: (e: any) => {
                //     const { invoiceNumber } = e
                //     return `${invoiceNumber}`
                // },
            },
            {
                field: 'client',
                localizedTitle: 'noun.client',
            },
            {
                field: 'invoiceDate',
                localizedTitle: 'noun.date',
            },
            {
                field: 'invoiceState',
                localizedTitle: 'strings.invoice_status',
                textAlign: 'center',
                formatter: (e: any) => {
                    return <InvoiceStateRenderer state={e} />
                },
            },
            {
                field: 'amountFormatted',
                localizedTitle: 'noun.amount',
            },
        ],
        actions: [
            {
                actionType: PowerGridAction.Download,
                onClick: (invoice: any) => {
                    setSelectedInvoiceId(invoice.id)
                },
            },
            {
                actionType: PowerGridAction.Edit,
                onClick: (invoice: any) => {
                    setEditableInvoiceId(invoice.id)
                },
            },
            {
                actionType: PowerGridAction.Delete,
                onClick: (invoice: any) => {
                    deleteInvoice(invoice.id)
                },
            },
        ],
    }

    return (
        <>
            <div className='button-container'>
                {years.map(it => {
                    return (<Button 
                        size='small' 
                        active={it.isSelected} 
                        key={it.value}
                        onClick={() => onKeySelect(it.value)}
                        >
                            {it.value}
                    </Button>)
                })}
            
            </div>
            <PowerGrid config={config}></PowerGrid>
            <LanguageContext>
                {selectedInvoiceId && (
                    <GeneratedInvoice
                        invoiceId={selectedInvoiceId}
                        isOpen={!!selectedInvoiceId}
                        onClose={() => {
                            setSelectedInvoiceId(null)
                        }}
                    />
                )}
            </LanguageContext>
        </>
    )
}

export default withTranslation()(InvoicesTable)
