import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import ClientsTable from '../../common/ui/ClientsTable'
import ListScreen from '../../common/ui/screen/ListScreen'
import { Button } from 'semantic-ui-react'
import { AddressCreate } from '../../models/Address'
import clientApiService from '../../services/clientApiService'
import AddNewClient, { ContractForm } from './crud/AddNewClient'
import { Client } from '../../models/Client'
import { useToast } from '../../common/ui/toast/ToastProvider'
import { ToastType } from '../../common/ui/toast/ToastItem'
import useCompanies from '../../hooks/useCompanies'
import EditClient from './crud/EditClient'

interface _Props extends WithTranslation {}

const Clients: React.VFC<_Props> = (props) => {
    const { t } = props

    const toastContext = useToast()

    const [isOpen, setIsOpen] = React.useState(false)
    const [isEditOpen, setIsEditOpen] = React.useState(false)
    const { data: clients, mutate } = useCompanies()
    const [editableClient, setEditableClient] = React.useState<any>()

    const onNewClientClose = () => {
        setIsOpen(false)
    }

    const onOpen = () => {
        setIsOpen(true)
    }

    const onEditClientClose = () => {
        setIsEditOpen(false)
    }

    const onEditOpen = (client: any) => {
        setEditableClient(client)
        setIsEditOpen(true)
    }

    const onSave = async (
        name: string,
        email: string,
        address?: AddressCreate,
        addressId?: number,
        contracts?: ContractForm[],
        tin?: string,
        note?: string,
        paymentDueDays?: number,
        bankAccountNumber?: string
    ) => {
        try {
            await clientApiService.addNew(
                name,
                email,
                address,
                tin,
                addressId,
                note,
                contracts,
                bankAccountNumber
            )
            toastContext.showMessage({
                type: ToastType.SUCCESS,
                title: t('verb.created'),
                description: t('strings.client_created'),
            })
            mutate()
            onNewClientClose()
        } catch (error) {
            toastContext.showMessage({
                type: ToastType.ERROR,
                title: t('verb.created'),
                description: t('errors.unable_to_create_client'),
            })
        }
    }

    const editAction = async (
        id: number,
        email: string,
        contracts?: any[],
        pin?: string,
        note?: string,
        bankAccountNumber?: string
    ) => {
        try {
            await clientApiService.edit(
                id,
                email,
                contracts,
                pin,
                note,
                bankAccountNumber
            )
            toastContext.showMessage({
                type: ToastType.SUCCESS,
                title: t('verb.updated'),
                description: t('strings.client_updated'),
            })
            mutate()
            onEditClientClose()
        } catch (error) {
            toastContext.showMessage({
                type: ToastType.ERROR,
                title: t('verb.updated'),
                description: t('errors.unable_to_edit_client'),
            })
        }
    }

    const deleteAction = async (client: Client) => {
        try {
            await clientApiService.delete(client.id)
            toastContext.showMessage({
                type: ToastType.SUCCESS,
                title: t('verb.deleted'),
                description: t('strings.client_deleted'),
            })
            mutate()
            onNewClientClose()
        } catch (error) {
            toastContext.showMessage({
                type: ToastType.ERROR,
                title: t('verb.deleted'),
                description: t('errors.unable_to_create_client'),
            })
        }

        onNewClientClose()
        setShouldReload(true)
    }

    return (
        <>
            <ListScreen
                title={t('noun.clients')}
                right={
                    <div>
                        <Button primary onClick={() => onOpen()}>
                            {t('strings.new_client')}
                        </Button>
                    </div>
                }
            >
                <ClientsTable
                    clients={clients}
                    deleteAction={deleteAction}
                    editAction={onEditOpen}
                ></ClientsTable>
            </ListScreen>
            {isOpen && (
                <AddNewClient
                    isOpen={isOpen}
                    onClose={onNewClientClose}
                    onSave={onSave}
                />
            )}
            {isEditOpen && editableClient && (
                <EditClient
                    client={editableClient}
                    isOpen={isEditOpen}
                    onClose={onEditClientClose}
                    onSave={editAction}
                />
            )}
        </>
    )
}

export default withTranslation()(Clients)
