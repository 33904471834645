import { withTranslation } from 'react-i18next'
import './App.scss'

import NewInvoice from '../../pages/invoices/new-invoice/NewInvoice'
import Dashboard from '../../pages/dashboard/Dashboard'
import Invoices from '../../pages/invoices/Invoices'
import SubView from '../../pages/invoices/InvoicesElements/Submitted/SubActionView/SubView'
import AddressScreen from '../../pages/address/AddressScreen'
import Clients from '../../pages/clients/ClientsScreen'
import Settings from '../../pages/settings/Settings'
import LoginForm from '../../pages/authorization/Login'
import { Helmet } from 'react-helmet'

import DashPic from '../images/menu/dashboard.svg'
import DashPicSelected from '../images/menu/dashboard_selected.svg'
import InvoicesPic from '../images/menu/invoices.svg'
import InvoicesPicSelected from '../images/menu/invoices_selected.svg'
import ClientPic from '../images/menu/clients.svg'
import ClientPicSelected from '../images/menu/clients_selected.svg'
import SettingsPic from '../images/menu/settings.svg'
import SettingsPicSelected from '../images/menu/settings_selected.svg'
import LogoPic from '../images/logo_svg_2.svg'
import ToastProvider from '../ui/toast/ToastProvider'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import PowerMenu from '../ui/menu/PowerMenu'
import PowerMenuItem from '../ui/menu/PowerMenuItem'
import useAuthTokens from '../../hooks/useAuthTokens'
import Header from './Header'
import UpdateInvoiceScreen from '../../pages/invoices/new-invoice/UpdateInvoiceScreen'

const App = (props) => {
    const { t } = props
    const [token, setToken] = useAuthTokens()

    const onLogout = () => {
        setToken(null)
    }

    if (!token) {
        return (
            <Router>
                <LoginForm onSetToken={setToken}></LoginForm>
            </Router>
        )
    }

    return (
        <Router>
            <ToastProvider>
                <div className='main-app-container'>
                    <Helmet>
                        <title>Kill Bills</title>
                    </Helmet>
                    <nav className='main-app-left-navigation-pane'>
                        <header>
                            <img
                                className='navigation-pane-logo'
                                src={LogoPic}
                                alt=''
                            />
                        </header>
                        <PowerMenu>
                            <PowerMenuItem
                                imageSelected={DashPicSelected}
                                image={DashPic}
                                text={t('noun.dashboard')}
                                link='/'
                                isSelected={true}
                            ></PowerMenuItem>
                            <PowerMenuItem
                                imageSelected={InvoicesPicSelected}
                                image={InvoicesPic}
                                text={t('noun.invoices')}
                                link='/Invoices'
                            ></PowerMenuItem>
                            <PowerMenuItem
                                imageSelected={ClientPicSelected}
                                image={ClientPic}
                                text={t('noun.clients')}
                                link='/Clients'
                            ></PowerMenuItem>
                            <PowerMenuItem
                                imageSelected={ClientPicSelected}
                                image={ClientPic}
                                text={t('noun.address')}
                                link='/Address'
                            ></PowerMenuItem>
                            <PowerMenuItem
                                imageSelected={SettingsPicSelected}
                                image={SettingsPic}
                                text={t('noun.settings')}
                                link='/Settings'
                            ></PowerMenuItem>
                        </PowerMenu>
                    </nav>

                    <section className='main-app-right-content-pane'>
                        <div className='main-app-user-header'>
                            <Header
                                username={token.username}
                                onLogout={onLogout}
                            ></Header>
                        </div>

                        <section className='main-app-content'>
                            <Routes>
                                <Route path='/' exact element={<Dashboard />} />

                                <Route
                                    path='/Dashboard/Submitted'
                                    element={<SubView />}
                                />

                                <Route
                                    path='/Invoices'
                                    element={<Invoices />}
                                />

                                <Route
                                    path='/Invoices/NewInvoice'
                                    element={<NewInvoice />}
                                />

                                <Route path='/Clients' element={<Clients />} />

                                <Route
                                    path='/Address'
                                    element={<AddressScreen />}
                                />
                                <Route
                                    path='/Settings'
                                    element={<Settings />}
                                />
                                <Route
                                    path='/Invoices/:invoiceId'
                                    element={<UpdateInvoiceScreen />}
                                />
                            </Routes>
                        </section>
                    </section>
                </div>
            </ToastProvider>
        </Router>
    )
}

export default withTranslation()(App)
