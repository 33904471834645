import React from 'react'
import PowerFlex from '../ui/layout/PowerFlex'
import { Dropdown, Icon, Menu } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

interface Props {
    username?: string
    onLogout?(): void
}

const Header: React.VFC<Props> = (props) => {
    const { username, onLogout } = props
    const navigate = useNavigate()

    const executeLogout = () => {
        if (onLogout) {
            onLogout()
        }

        navigate('/', { replace: true })
    }

    return (
        <PowerFlex
            container
            alignItems='center'
            gap={1}
            justifyContent='flex-end'
        >
            <Icon
                style={{ marginTop: '0.1rem' }}
                name='user circle'
                size='huge'
            ></Icon>
            <Menu style={{ marginRight: '1rem' }}>
                <Dropdown item text={username}>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={executeLogout}>
                            Logout
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu>
        </PowerFlex>
    )
}

export default Header
