import React from 'react'
import Screen from './Screen'

interface _Props {
    title: any
    right?: any
    children: any
}

const ListScreen = (props: _Props) => {
    return (
        <Screen title={props.title} right={props.right}>
            <div className='screen-list'>{props.children}</div>
        </Screen>
    )
}

export default ListScreen
