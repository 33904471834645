import React, { useEffect, useMemo } from 'react'
import { Address } from '../models/Address'
import addressService from '../services/addressService'

export default function useAddresses() {
    const [addresses, setAddresses] = React.useState<Address[]>([])

    useEffect(() => {
        addressService
            .getAll()
            .then((r) => {
                setAddresses(r.data)
            })
            .catch((err) => {
                console.error(err)
                setAddresses([])
            })
    }, [])

    return useMemo(() => {
        return addresses
    }, [addresses])
}

export function asAddressDescription(address: Address): string {
    const results: string[] = [address.address1]

    if (address.address2) {
        results.push(address.address2)
    }

    results.push(address.city, address.zip)

    if (address.state) {
        results.push(address.state)
    }

    return results.join(', ')
}
