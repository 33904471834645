import React from 'react'
import {
    FieldErrors,
    SubmitErrorHandler,
    SubmitHandler,
    useForm,
} from 'react-hook-form'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Button, Form } from 'semantic-ui-react'
import PowerSelect from '../../core/form/PowerSelect'
import TextInputForm from '../../core/form/TextInputForm'
import PowerPopup from '../../ui/PowerPopup'
import { useCountriesForDropdown } from '../../../hooks/useCountries'
import {
    CBLineItem,
    CBLineItemCreate,
    LineItemUnit,
} from '../../../models/CBLineItem'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
interface Props extends WithTranslation {
    isOpen: boolean
    isEnglishRequired?: boolean
    existingItem?: CBLineItem
    onSaveOrEdit(lineItem: CBLineItemCreate, existingId?: number): void
    onClose(): void
}

Yup.addMethod(Yup.number, 'decimalNumber', function (errorMessage) {
    return this.test(`decimal-number-test`, errorMessage, function (value) {
        const { path, createError } = this

        return (
            (value && Number(value) > 0) ||
            createError({ path, message: errorMessage })
        )
    })
})

const validationSchema = Yup.object().shape({
    nameHr: Yup.string().required().min(4),
    nameEn: Yup.string().optional(),
    unitPrice: (Yup.number() as any).decimalNumber(),
    currencyCode: Yup.string().required().min(3).max(3),
    unit: Yup.mixed()
        .oneOf([LineItemUnit.HOURLY, LineItemUnit.SINGLE])
        .required(),
})

interface FormFields {
    nameEn?: string
    nameHr: string
    unitPrice: number
    currencyCode: string
    unit: LineItemUnit
}

const AddEditLineItem: React.VFC<Props> = (props) => {
    const {
        t,
        isOpen,
        isEnglishRequired,
        onSaveOrEdit,
        onClose: propsOnClose,
        existingItem,
    } = props

    const defaultValues: Partial<FormFields> = {
        unit: LineItemUnit.HOURLY,
        currencyCode: (existingItem && existingItem.currency.code) || 'EUR',
        ...existingItem,
    }

    const [isLoading, setIsLoading] = React.useState(false)
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormFields>({
        resolver: yupResolver(validationSchema),
        defaultValues,
    })

    const onSubmit: SubmitHandler<FormFields> = async (deita: FormFields) => {
        setIsLoading(true)
        const lineItemCreate: CBLineItemCreate = {
            ...deita,
            unitPrice: deita.unitPrice,
            currency: deita.currencyCode,
        }

        await onSaveOrEdit(lineItemCreate, existingItem?.id)

        setIsLoading(false)
    }
    const onError: SubmitErrorHandler<FormFields> = (
        errors: FieldErrors<FormFields>,
        e: any
    ) => {}

    const countriesDropdownOptions = useCountriesForDropdown()

    return (
        <PowerPopup
            title={t('strings.new_line_item')}
            onClose={propsOnClose}
            isOpen={isOpen}
            isLoading={isLoading}
            actions={
                <Button submit form='createLineItemForm' primary>
                    {existingItem?.id
                        ? t('strings.update_existing')
                        : t('strings.create_new')}
                </Button>
            }
        >
            <Form
                id='createLineItemForm'
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <Form.Group widths='equal'>
                    <TextInputForm
                        register={register}
                        name='nameHr'
                        label={t('strings.croatian_name')}
                        required={true}
                        errors={errors}
                    />
                    <TextInputForm
                        register={register}
                        name='nameEn'
                        errors={errors}
                        required={isEnglishRequired}
                        label={t('strings.english_name')}
                    />
                </Form.Group>

                <Form.Group widths='equal'>
                    <TextInputForm
                        register={register}
                        name='unitPrice'
                        required={true}
                        errors={errors}
                        label={t('strings.unit_price')}
                    />
                    <PowerSelect
                        name='unit'
                        control={control}
                        label={t('noun.unit')}
                        options={[
                            {
                                key: LineItemUnit.HOURLY,
                                value: LineItemUnit.HOURLY,
                                text: t('strings.hourly_rate'),
                            },
                            {
                                key: LineItemUnit.SINGLE,
                                value: LineItemUnit.SINGLE,
                                text: t('noun.piece'),
                            },
                        ]}
                    ></PowerSelect>
                    <PowerSelect
                        name='currencyCode'
                        label={t('noun.currency')}
                        control={control}
                        options={[
                            {
                                key: 'USD',
                                value: 'USD',
                                text: t('currency.usd'),
                            },
                            {
                                key: 'EUR',
                                value: 'EUR',
                                text: t('currency.eur'),
                            },
                        ]}
                    ></PowerSelect>
                </Form.Group>
            </Form>
        </PowerPopup>
    )
}

export default withTranslation()(AddEditLineItem)
