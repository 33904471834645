import { yupResolver } from '@hookform/resolvers/yup'
import { DateTime } from 'luxon'
import React from 'react'
import {
    UnpackNestedValue,
    useFieldArray,
    useForm,
    useWatch,
} from 'react-hook-form'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
    Button,
    Dimmer,
    Dropdown,
    Form,
    Grid,
    Icon,
    Loader,
    Segment,
} from 'semantic-ui-react'
import * as Yup from 'yup'
import '../../../../src/common/app/styling/Forms.scss'
import AddEditLineItem from '../../../common/app/fragments/AddEditNewLineItem'
import PowerDate from '../../../common/core/form/PowerDate'
import PowerSelect from '../../../common/core/form/PowerSelect'
import TextInputForm from '../../../common/core/form/TextInputForm'
import PowerFlex from '../../../common/ui/layout/PowerFlex'
import { ToastType } from '../../../common/ui/toast/ToastItem'
import { useToast } from '../../../common/ui/toast/ToastProvider'
import { useCompaniesForDropdown } from '../../../hooks/useCompanies'
import { dropdownLineItems, useLineItemsSwr } from '../../../hooks/useLineItems'
import { CBLineItemCreate, LineItemUnit } from '../../../models/CBLineItem'
import lineItemService from '../../../services/LineItemService'
import './NewInvoice.scss'
import { InvoiceLineItem } from './NewInvoiceModels'
import SubtotalRenderer from './SubtotalRenderer'
import { InvoiceDataDto } from '../../../models/invoice/InvoiceDataDto'
import { UpdateInvoiceDto } from '../../../models/invoice/UpdateInvoiceDto'
import invoiceService from '../../../services/invoiceService'

interface FormFields {
    clientId: number
    invoiceNumber: string
    contractType?: string
    billingDate: Date
    deliveryDate: Date
    paymentDueDate: Date
    paymentMethod: string
    operator: string
    extraInfo?: string
    referenceNumber?: string
    items: InvoiceLineItem[]
}

declare module 'yup' {
    // tslint:disable-next-line
    interface ArraySchema<T> {
        unique(message: any, mapper?: (a: T) => T): ArraySchema<T>
    }
}

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a: any) => a) {
    return this.test('unique', message, function (list) {
        if (!list) {
            return true
        }
        return list.length === new Set(list.map(mapper)).size
    })
})

const formatDateForApi = (date: Date) => {
    const format = 'yyyy-MM-dd'
    return DateTime.fromJSDate(date).toFormat(format)
}

const validationSchema = Yup.object().shape({
    clientId: Yup.number().required(),
    billingDate: Yup.date().required(),
    deliveryDate: Yup.date().required(),
    paymentDueDate: Yup.date().required(),
    paymentMethod: Yup.string().required(),
    contractType: Yup.string().optional(),
    referenceNumber: Yup.string().optional().nullable(),
    extraInfo: Yup.string().optional().nullable(),
    items: Yup.array()
        .of(
            Yup.object().shape({
                protoId: Yup.number().required(),
                quantity: Yup.number().required(),
                description: Yup.string().required(),
                descriptionEn: Yup.string().optional(),
                price: Yup.number().required(),
                unit: Yup.string().required(),
                currency: Yup.string().required(),
                discountPercent: Yup.number().min(0).max(99.99).notRequired(),
            })
        )
        .required()
        .unique('Only unique items can be added', (e: any) => e.protoId)
        .min(1, 'At least 1 item required'),
})

interface Props extends WithTranslation {
    existingInvoice: InvoiceDataDto
    onSuccess?(): void
}

const UpdateInvoice: React.VFC<Props> = (props) => {
    const { t, existingInvoice, onSuccess } = props
    const [isAddNewItemOpened, setAddNewItemOpened] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const companiesDropdown = useCompaniesForDropdown()
    const toast = useToast()

    const defaultValues: Partial<FormFields> = {
        clientId: existingInvoice.clientId,
        invoiceNumber: existingInvoice.invoiceNumber,
        operator: existingInvoice.operator,
        paymentMethod: t(
            'enums.payment_type_' + existingInvoice.paymentMethod.toLowerCase()
        ),
        contractType: existingInvoice.contractType,
        billingDate: DateTime.fromISO(existingInvoice.createDate).toJSDate(),
        deliveryDate: DateTime.fromISO(existingInvoice.deliveryDate).toJSDate(),
        paymentDueDate: DateTime.fromISO(
            existingInvoice.paymentDueDate
        ).toJSDate(),
        extraInfo: existingInvoice.extraInfo,
        referenceNumber: existingInvoice.referenceNumber,
        items: existingInvoice.lineItems.map((lineItem): InvoiceLineItem => {
            return {
                protoId: lineItem.productId,
                quantity: lineItem.quantity,
                price: lineItem.price,
                discountPercent: lineItem.discountPercent || 0,
                currency: lineItem.currencyCode,
                description: lineItem.description,
                unit: lineItem.unit == LineItemUnit.HOURLY ? 'h' : 'pc',
                descriptionEn: lineItem.descriptionEn,
            }
        }),
    }

    const { control, register, handleSubmit, setValue, formState } =
        useForm<FormFields>({
            resolver: yupResolver(validationSchema),
            defaultValues,
        })

    const { errors } = formState
    const [selectedItemId, setSelectedItemId] = React.useState<number>()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'items',
    })

    const currentLineItems =
        (useWatch<FormFields>({
            control,
            name: 'items',
        }) as InvoiceLineItem[]) || []

    const { data: lineItems, mutate: mutateLineItems } = useLineItemsSwr(
        existingInvoice.clientId
    )

    if (!lineItems) {
        return <></>
    }

    const selectLineItems = dropdownLineItems(lineItems)
    const invoiceCurrency = lineItems.map((e) => e.currency)[0]?.code ?? 'USD'

    const onSubmit = async (data: UnpackNestedValue<FormFields>) => {
        const query: UpdateInvoiceDto = {
            paymentDueDate: formatDateForApi(data.paymentDueDate),
            referenceNumber: data.referenceNumber,
            extraInfo: data.extraInfo,
            lineItems: data.items.map((item) => {
                return {
                    ...item,
                    id: item.protoId,
                }
            }),
        }
        setIsLoading(true)
        try {
            const result = await invoiceService.updateInvoice(
                existingInvoice.id,
                query
            )
            toast.showMessage({
                type: ToastType.SUCCESS,
                title: t('noun.invoice'),
                description: `${t('noun.success')}! Number: ${
                    result.data.number
                }`,
            })
            onSuccess?.()
        } catch (err: any) {
            toast.showError(err.message)
        }
        setIsLoading(false)
    }

    const sendCreateItem = (it: CBLineItemCreate) => {
        lineItemService
            .addNew(it.nameHr, it.unitPrice, it.currency, it.unit, it.nameEn)
            .then((it) => {
                mutateLineItems()
                setAddNewItemOpened(false)
                toast.showMessage({
                    title: 'Kill bills!',
                    description: 'New Line Item created:' + it.data.id,
                    type: ToastType.SUCCESS,
                })
                const newLineItem = it.data
                append({
                    currency: newLineItem.currency.code,
                    description: newLineItem.nameHr,
                    descriptionEn: newLineItem.nameEn,
                    protoId: newLineItem.id,
                    price: newLineItem.unitPrice,
                    unit: newLineItem.unit == LineItemUnit.HOURLY ? 'h' : 'pc',
                    discountPercent: 0,
                    quantity: 1,
                })
            })
    }

    const onCreateItem = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()

        setAddNewItemOpened(true)
    }

    const onAddNewItem = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        setSelectedItemId(0)

        if (!selectedItemId) {
            return
        }

        const selectedLineItem = lineItems.filter(
            (item) => item.id === selectedItemId
        )[0]

        if (!selectedLineItem) {
            return
        }

        if (currentLineItems.some((e) => e.protoId === selectedItemId)) {
            toast.showError('Item already exists in the list')
            return
        }

        append({
            currency: selectedLineItem.currency.code,
            description: selectedLineItem.nameHr,
            descriptionEn: selectedLineItem.nameEn,
            protoId: selectedItemId,
            price: selectedLineItem.unitPrice,
            unit: selectedLineItem.unit == LineItemUnit.HOURLY ? 'h' : 'pc',
            discountPercent: 0.0,
            quantity: 1,
        })
    }

    const onDropdownChanged = (e: any, formValues: any) => {
        setSelectedItemId(formValues.value)
    }

    const onRemoveItem = (
        event: React.MouseEvent<HTMLButtonElement>,
        index: number
    ) => {
        event.stopPropagation()
        event.preventDefault()
        remove(index)
    }

    console.log(errors)

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Segment className='new-invoice-client-info'>
                    <Form.Group widths='equal'>
                        <PowerSelect
                            name='clientId'
                            label={t('noun.client')}
                            control={control}
                            fluid
                            placeholder={t('strings.select_client')}
                            disabled
                            options={companiesDropdown}
                        />
                        <TextInputForm
                            name='invoiceNumber'
                            label={t('strings.invoice_number')}
                            required
                            disabled
                            register={register}
                            errors={errors}
                        ></TextInputForm>
                        <TextInputForm
                            disabled
                            name='operator'
                            label={t('strings.operator_name')}
                            register={register}
                        ></TextInputForm>
                    </Form.Group>
                    <Form.Group widths={3}>
                        <TextInputForm
                            name='contractType'
                            label={t('noun.contract')}
                            disabled
                            register={register}
                        ></TextInputForm>
                        <TextInputForm
                            name='extraInfo'
                            label={t('strings.internal_number')}
                            register={register}
                        ></TextInputForm>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <PowerDate
                            control={control}
                            label={t('strings.invoice_date')}
                            name='billingDate'
                            required
                            disabled
                            maxDate={new Date()}
                            errors={errors}
                        ></PowerDate>
                        <PowerDate
                            control={control}
                            label={t('strings.delivery_date')}
                            name='deliveryDate'
                            errors={errors}
                            maxDate={new Date()}
                            required
                            disabled
                        ></PowerDate>
                        <PowerDate
                            control={control}
                            label={t('strings.payment_due_date')}
                            name='paymentDueDate'
                            errors={errors}
                            required
                            minDate={defaultValues.billingDate}
                            clearable
                        ></PowerDate>
                    </Form.Group>
                    <Grid>
                        <Grid.Row stretched>
                            <Grid.Column width={5}>
                                <TextInputForm
                                    name='paymentMethod'
                                    label={t('strings.payment_method')}
                                    register={register}
                                    disabled
                                    errors={errors}
                                ></TextInputForm>
                                <TextInputForm
                                    name='referenceNumber'
                                    label={t('strings.reference_number')}
                                    required={invoiceCurrency === 'HRK'}
                                    register={register}
                                    errors={errors}
                                ></TextInputForm>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <PowerFlex
                    container
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <PowerFlex>
                        <h2 style={{ marginBottom: '1rem' }}>Line items</h2>
                    </PowerFlex>
                </PowerFlex>
                {fields.map((field: InvoiceLineItem, index) => {
                    const itemErrors = errors.items?.[index]
                    return (
                        <form
                            key={index}
                            onSubmit={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                        >
                            <Segment style={{ marginBottom: '1rem' }}>
                                <Grid verticalAlign='top' key={field.protoId}>
                                    <input
                                        style={{ display: 'none' }}
                                        value={field.protoId}
                                    ></input>
                                    <Grid.Row columns={16}>
                                        <Grid.Column width={7}>
                                            <TextInputForm
                                                name={`items.${index}.description`}
                                                label={t('noun.description')}
                                                register={register}
                                                errors={itemErrors}
                                            ></TextInputForm>
                                            <TextInputForm
                                                name={`items.${index}.descriptionEn`}
                                                label={t(
                                                    'strings.description_en'
                                                )}
                                                register={register}
                                                errors={itemErrors}
                                            ></TextInputForm>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <TextInputForm
                                                name={`items.${index}.quantity`}
                                                label={t('noun.qty')}
                                                errors={itemErrors}
                                                rightLabel={{
                                                    basic: true,
                                                    content: field.unit,
                                                }}
                                                register={register}
                                            ></TextInputForm>
                                            <TextInputForm
                                                name={`items.${index}.price`}
                                                label={t('strings.unit_price')}
                                                register={register}
                                                rightLabel={{
                                                    basic: true,
                                                    content: field.currency,
                                                }}
                                                errors={itemErrors}
                                            ></TextInputForm>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <TextInputForm
                                                name={`items.${index}.discountPercent`}
                                                label={t(
                                                    'strings.discount_percent'
                                                )}
                                                register={register}
                                                rightLabel={{
                                                    basic: true,
                                                    content: '%',
                                                }}
                                                errors={itemErrors}
                                            ></TextInputForm>
                                            <SubtotalRenderer
                                                id={field.protoId}
                                                items={currentLineItems}
                                            ></SubtotalRenderer>
                                        </Grid.Column>
                                        <Grid.Column width={1}>
                                            <Form.Button
                                                icon
                                                color='red'
                                                fluid
                                                style={{ marginTop: '1.69rem' }}
                                                onClick={(event) =>
                                                    onRemoveItem(event, index)
                                                }
                                            >
                                                <Icon name='trash alternate'></Icon>
                                            </Form.Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </form>
                    )
                })}
                <Segment style={{ marginTop: '2rem' }}>
                    <form>
                        <Grid verticalAlign='bottom'>
                            <Grid.Column computer={10}>
                                <Dropdown
                                    selection
                                    search
                                    fluid
                                    value={selectedItemId}
                                    placeholder={t('strings.type_line_items')}
                                    options={selectLineItems}
                                    onChange={onDropdownChanged}
                                ></Dropdown>
                            </Grid.Column>
                            <Grid.Column computer={6}>
                                <Button
                                    primary
                                    onClick={(event) => onAddNewItem(event)}
                                >
                                    {t('strings.add_item')}
                                </Button>
                                <Button
                                    secondary
                                    onClick={(event) => onCreateItem(event)}
                                >
                                    {t('strings.create_new_item')}
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </form>
                </Segment>
                <PowerFlex container justifyContent='flex-end'>
                    <h3>
                        Total{' '}
                        {currentLineItems.reduce((acc, e) => {
                            return (
                                acc +
                                e.quantity *
                                    e.price *
                                    (1 - (e.discountPercent || 0) / 100)
                            )
                        }, 0)}{' '}
                        {invoiceCurrency}
                    </h3>
                </PowerFlex>
                <Button primary disabled={isLoading}>
                    Update Invoice
                </Button>
                <Dimmer active={isLoading} inverted>
                    <Loader inline></Loader>
                </Dimmer>
            </Form>
            {isAddNewItemOpened && (
                <AddEditLineItem
                    isOpen={isAddNewItemOpened}
                    isEnglishRequired={invoiceCurrency !== 'HRK'}
                    onClose={() => setAddNewItemOpened(false)}
                    onSaveOrEdit={sendCreateItem}
                ></AddEditLineItem>
            )}
        </>
    )
}

export default withTranslation()(UpdateInvoice)
