import React from 'react'
import { InvoiceScreenDto } from '../models/invoice/InvoiceScreenDto'
import invoiceService from '../services/invoiceService'

export default function useNewInvoiceDataPackage(): [
    InvoiceScreenDto | undefined,
    (it: boolean) => void
] {
    const [dataPackage, setNewDataPackage] = React.useState<InvoiceScreenDto>()
    const [needsReload, setNeedsReload] = React.useState<boolean>(true)

    React.useEffect(() => {
        if (!needsReload) {
            return
        }
        invoiceService
            .newInvoiceDataPackage()
            .then((r) => {
                setNewDataPackage(r)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setNeedsReload(false)
            })
    }, [needsReload])

    return [dataPackage, setNeedsReload]
}
