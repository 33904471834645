import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import './Settings.scss'
import Screen from '../../common/ui/screen/Screen'
import LineItemEditor from './LineItemEditor'
import { Tab } from 'semantic-ui-react'
import General from './General'

interface _Props extends WithTranslation {}

const SettingsScreen: React.VFC<_Props> = (props: _Props) => {
    const { t } = props

    const panes = [
        {
            menuItem: t('noun.general'),
            render: () => (
                <Tab.Pane>
                    <General />
                </Tab.Pane>
            ),
        },
        {
            menuItem: t('strings.line_items'),
            render: () => (
                <Tab.Pane>
                    <LineItemEditor />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'POS',
            render: () => <Tab.Pane>Tab 3 Content</Tab.Pane>,
        },
    ]

    return (
        <Screen title={t('noun.settings')}>
            <Tab
                className='powertab'
                menu={{
                    secondary: true,
                    pointing: true,
                    borderless: true,
                }}
                panes={panes}
            />
        </Screen>
    )
}

export default withTranslation()(SettingsScreen)
