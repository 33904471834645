import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

import './commonData.scss'

interface Props extends WithTranslation {
    commonData: any
    paymentData: any
}

const CommonData: React.VFC<Props> = (props) => {
    const { commonData, paymentData, t } = props

    return (
        <div className='cData middle-section-item'>
            <div className='card-body'>
                <div className='upper-part'>
                    <p className='invoice-number'>
                        {t('noun.invoice')} {t('noun.number')}{' '}
                        {commonData.invoiceNumber}
                    </p>
                    <p>
                        {commonData.extraInfo && (
                            <>
                                {t('strings.internal_number')}
                                {commonData.extraInfo}
                            </>
                        )}
                    </p>
                </div>
                <div className='lower-part'>
                    <span>
                        {t('strings.invoice_date')}: {commonData.createDate}
                    </span>
                    <span>
                        {t('strings.delivery_date')}: {commonData.deliveryDate}
                    </span>
                    <span>
                        {t('strings.payment_due_date')}:{' '}
                        {commonData.paymentDueDate}
                    </span>
                    <span>
                        {`${t('strings.payment_method')}: ${
                            paymentData.paymentMethod
                        }`}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(CommonData)
