import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

import CommonData from './generated-invoice-elements/commonData/CommonData'
import InvoiceContent from './generated-invoice-elements/content/InvoiceContent'
import CompanyInfo from './generated-invoice-elements/commonData/CompanyInfo'
import Logo from '../../../../common/images/logo_svg_2.svg'
import Crta from '../../../../common/images/crta_tanja.svg'
import { Helmet } from 'react-helmet'

import './PrintInvoice.scss'
import { Button, Grid, Portal } from 'semantic-ui-react'
import { useInvoicePrintById } from '../../../../hooks/useInvoices'

interface Props extends WithTranslation {
    isOpen: boolean
    invoiceId: number
    onClose?(): void
}

const PrintInvoice: React.VFC<Props> = (props) => {
    const { t, i18n, isOpen, invoiceId, onClose } = props

    const invParts = useInvoicePrintById(invoiceId, i18n.language)

    if (!invParts) {
        return <></>
    }

    const { client, commonData, sender, paymentData, operator, content } =
        invParts

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language)
    }

    const isBilingual = client.countryCode.toLowerCase() !== 'hr'

    if (!isBilingual && !i18n.language.includes('hr')) {
        i18n.changeLanguage('hr')
        return <div />
    }

    if (!client) {
        return <div />
    }

    return (
        <Portal open={isOpen} closeOnEscape onClose={onClose}>
            <div className='invoice-root-div'>
                <Helmet>
                    <title>{commonData.printName}</title>
                </Helmet>
                <div className='invoice-root-container'>
                    {isBilingual && (
                        <div className='language-switcher'>
                            <Grid className='buttons'>
                                <Grid.Row columns='equal'>
                                    <Grid.Column>
                                        <Button
                                            primary
                                            onClick={() => changeLanguage('en')}
                                        >
                                            English
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button
                                            secondary
                                            onClick={() => changeLanguage('hr')}
                                        >
                                            Croatian
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    )}
                    <div className='invoice'>
                        <section className='upper'>
                            <header className='codebase'>
                                <img className='log' src={Logo} alt='' />
                            </header>
                            <div className='sender-company-info'>
                                <h1 className='invoice-type'>
                                    {commonData.type}
                                </h1>
                                <p className='company-name'>{sender.title}</p>
                                <p>
                                    {sender.address1}, {sender.zip}{' '}
                                    {sender.city}
                                </p>
                                <p>{sender.country}</p>
                                <a href='mailto'>{sender.email}</a>
                                <p>
                                    {t('strings.tax_number')}: {sender.pin}
                                </p>
                                <p>
                                    {t('noun.vat')}#: HR{sender.pin}
                                </p>
                            </div>
                        </section>
                        <section className='middle-section'>
                            <CommonData
                                commonData={commonData}
                                paymentData={paymentData}
                            />
                            <section className='client middle-section-item'>
                                <div className='card-body'>
                                    <div className='client-title'>
                                        <p>{t('verb.to')}:</p>
                                    </div>
                                    <div className='company-name'>
                                        <p>
                                            <strong>{client.title}</strong>
                                        </p>
                                        {client.pin && (
                                            <p>
                                                {`${t('strings.tax_number')}: ${
                                                    client.pin
                                                }`}
                                            </p>
                                        )}
                                    </div>
                                    <div className='client-body'>
                                        <p>{client.address1}</p>
                                        <p>{client.address2}</p>
                                        {client.countryCode == 'HR' ? (
                                            <p>
                                                {client.zip} {client.city}
                                            </p>
                                        ) : (
                                            <p>
                                                {client.city}, {client.zip}
                                            </p>
                                        )}
                                        <p>{client.state}</p>
                                        {client.countryCode !== 'HR' ? (
                                            <p>{client.country}</p>
                                        ) : null}
                                    </div>
                                </div>
                            </section>
                        </section>
                        <img className='crt' src={Crta} alt='' />
                        <InvoiceContent content={content} />
                    </div>
                    <footer className='invoice-lower-section'>
                        <section className='payment-and-operator'>
                            <p>
                                {t('strings.operator_name')}:{' '}
                                {operator.stationNumber}, {operator.name}
                            </p>
                            <p>
                                {t('strings.issued_by')}: {operator.name}
                            </p>
                            <div className='payment-data'>
                                <p>{t('strings.payment_details')}:</p>
                                <p className='iban'>
                                    {t('noun.iban')}: {paymentData.iban}
                                </p>
                                <p className='swift'>
                                    {t('noun.swift')}: {paymentData.swift}
                                </p>
                            </div>
                            {paymentData.referenceNumber && (
                                <div className='reference-number'>
                                    {t('strings.reference_number')}: HR00{' '}
                                    {paymentData.referenceNumber}
                                </div>
                            )}
                            {invParts.barcodeUrl && (
                                <div className='barcode'>
                                    <img src={invParts.barcodeUrl}></img>
                                </div>
                            )}
                        </section>
                        <section className='info'>
                            <hr className='rule' />
                            <ol className='foot-notes'>
                                {content.footnotes.map(
                                    (item: any, index: number) => {
                                        return <li key={index}>{item}</li>
                                    }
                                )}
                            </ol>
                        </section>
                        <CompanyInfo foot={commonData} />
                    </footer>
                </div>
            </div>
        </Portal>
    )
}

export default withTranslation()(PrintInvoice)
