import React from 'react'
import './PowerFlex.scss'

interface Props {
    direction?: 'row' | 'column'
    container?: boolean
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
    gap?: number
    justifyContent?:
        | 'flex-start'
        | 'center'
        | 'flex-end'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
}

const PowerFlex: React.FC<Props> = (props) => {
    const { direction, container, alignItems, gap, children, justifyContent } =
        props

    let classes: String[] = []

    if (direction) {
        classes.push('power-flex' + direction)
    }
    if (container) {
        classes.push('power-flex-container')
    }
    if (alignItems) {
        classes.push('power-flex-' + alignItems)
    }
    if (justifyContent) {
        classes.push('power-flex-' + justifyContent)
    }

    return (
        <div
            className={classes.join(' ')}
            style={{
                gap: `${gap ?? 0}rem`,
            }}
        >
            {children}
        </div>
    )
}

export default PowerFlex
