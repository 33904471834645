{
    "countables": {
        "hours_few": "{{count}} sata",
        "hours_one": "{{count}} sat",
        "hours_other": "{{count}} sati",
        "pc_few": "{{count}} kom",
        "pc_one": "{{count}} kom",
        "pc_other": "{{count}} kom"
    },
    "currency": {
        "eur": "Euro",
        "hrk": "Hrvatska kuna",
        "usd": "Američki dolar"
    },
    "enums": {
        "invoice_state_cleared": "Naplaćen",
        "invoice_state_draft": "Nacrt",
        "invoice_state_published": "Izdan",
        "payment_type_advance": "Predujam",
        "payment_type_cash": "Gotovina",
        "payment_type_wire": "Transakcijski račun",
        "unknown": "Unknown"
    },
    "errors": {
        "generic_error": "Došlo je do pogreške... zovi Bruna!",
        "unable_to_create_client": "Nije moguće napraviti klijenta",
        "unable_to_edit_client": "Nije moguće izmijeniti klijenta"
    },
    "noun": {
        "Dashboard": "Kontrolna ploča",
        "address": "Adresa",
        "amount": "Iznos",
        "city": "Grad",
        "client": "Klijent",
        "clients": "Klijenti",
        "contact": "Kontakt",
        "contract": "Ugovor",
        "contracts": "Ugovori",
        "country": "Država",
        "currency": "Valuta",
        "date": "Datum",
        "description": "Opis",
        "duration": "Trajanje",
        "general": "Općenito",
        "homepage": "Naslovnica",
        "iban": "IBAN",
        "invoice": "Račun",
        "invoices": "Računi",
        "link": "Poveznica",
        "loading": "Učitavanje",
        "month": "Mjesec",
        "name": "Naziv",
        "note": "Note",
        "number": "br.",
        "piece": "Komad",
        "qty": "Količina",
        "save": "Spasi",
        "search": "Pretraživanje",
        "settings": "Postavke",
        "state": "Savezna država/Regija",
        "status": "Stanje",
        "subtotal": "Iznos bez PDV-a",
        "success": "Uspjeh!",
        "summary": "Sažetak",
        "total": "Ukupno",
        "unit": "Obračunska jedinica",
        "user": "Korisnik",
        "users": "Korisnici",
        "vat": "PDV",
        "year": "Godina",
        "zip": "Poštanski broj"
    },
    "strings": {
        "about": "O nama",
        "add_item": "Dodaj stavku",
        "address2": "Kat, zgrada...",
        "client_created": "Novi klijent uspješno napravljen!",
        "client_deleted": "Klijent uspješno izbrisan",
        "client_updated": "Klijent uspješno nadograđen",
        "create_new": "Napravi novo",
        "create_new_item": "Izradi novu stavku",
        "croatian_name": "Hrvatski naziv",
        "delivery_date": "Datum isporuke",
        "description_en": "Opis (Engleski)",
        "discount_percent": "Popust (%)",
        "email_address": "E-mail adresa",
        "english_name": "Engleski naziv",
        "exit": "Jeste li sigurni?",
        "hourly_rate": "Satnica",
        "important_coordinates": "Važne koordinate",
        "internal_number": "Interni br. ",
        "invoice_date": "Datum izdavanja",
        "invoice_number": "Broj računa",
        "invoice_status": "Stanje računa",
        "issued_by": "Račun izdao",
        "line_items": "Stavke računa",
        "new_address": "Nova adresa",
        "new_client": "Novi klijent",
        "new_invoice": "Novi račun",
        "new_line_item": "Novi predmet",
        "no_results": "Nema rezultata.",
        "operator_name": "Operater",
        "payment_details": "Podatci za uplatu",
        "payment_due_date": "Datum dospijeća",
        "payment_method": "Način plaćanja",
        "reference_number": "Poziv na broj",
        "related_information": "Povezane informacije",
        "rent_contract": "Ugovor o najmu",
        "select_client": "Odabir klijenta",
        "select_contract": "Odabir ugovora",
        "sow_contract": "Ugovor o radu",
        "subcontract": "Podizvođač",
        "tax_number": "OIB",
        "type_line_items": "Upišite tekst za pretragu stavki...",
        "unit_price": "Jedinična cijena",
        "update_client": "Izmijeni klijenta",
        "update_existing": "Izmijeni postojeće",
        "use_existing_addr": "Iskoristi postojeću adresu",
        "vat_number": "PDV broj"
    },
    "verb": {
        "close": "Zatvori",
        "create": "Kreiraj",
        "created": "Obavljeno",
        "delete": "Izbriši",
        "edit": "Izmijeni",
        "filter_by": "Filtriraj",
        "login": "Prijavi se",
        "logout": "Odjavi se",
        "navigate": "Navigate",
        "order_by": "Sortiraj",
        "register": "Registracija",
        "signup": "Pridruži se",
        "to": "Za",
        "updated": "Nadograđeno"
    }
}
