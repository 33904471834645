import React from 'react'
import Screen from '../../common/ui/screen/Screen'

import { withTranslation } from 'react-i18next'

import './Dashboard.scss'
import JoppdCalc from './JoppdCalc'
import { t } from 'i18next'

const Dashboard: React.FC = (props: any) => {
    return (
        <Screen title={t('noun.dashboard')}>
            <JoppdCalc></JoppdCalc>
        </Screen>
    )
}

export default withTranslation()(Dashboard)
