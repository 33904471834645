import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

import ListScreen from '../../common/ui/screen/ListScreen'
import AddressTable from '../../common/ui/AddressTable'
import addressService from '../../services/addressService'
import { Address, AddressCreate } from '../../models/Address'
import { useToast } from '../../common/ui/toast/ToastProvider'
import { ToastType } from '../../common/ui/toast/ToastItem'
import AddNewAddress from './AddNewAddress'
import { Button } from 'semantic-ui-react'

interface _Props extends WithTranslation {}

const AddressScreen: React.VFC<_Props> = (props) => {
    const { t } = props
    const [shouldReload, setShouldReload] = React.useState(true)
    const [isAddNewOpened, setAddNewOpened] = React.useState(false)
    const [addresses, setAddresses] = React.useState<Address[]>([])
    const toasts = useToast()

    React.useEffect(() => {
        if (!shouldReload) {
            return
        }

        addressService
            .getAll()
            .then((e) => {
                setAddresses(e.data)
            })
            .finally(() => {
                setShouldReload(false)
            })
    }, [shouldReload])

    const sendEdit = (addr: Address) => {
        alert('Should EDIT item with id:' + addr.id)
    }

    const sendDelete = (addr: Address) => {
        addressService
            .delete(addr.id)
            .then((e) => {
                setShouldReload(true)
            })
            .catch((err) => {
                toasts.showMessage({
                    type: ToastType.ERROR,
                    description: err.toString(),
                    title: 'Deleting Address',
                })
            })
    }

    const sendCreate = (dto: AddressCreate) => {
        addressService
            .create(dto)
            .then((r) => {
                toasts.showMessage({
                    title: 'Address Created!',
                    description: 'New Object has ID of ' + r.data.id,
                    type: ToastType.SUCCESS,
                })
                setShouldReload(true)
            })
            .catch((e) => {
                toasts.showMessage({
                    title: 'Error creating address',
                    description: JSON.stringify(e),
                    type: ToastType.ERROR,
                })
            })
            .finally(() => {
                setAddNewOpened(false)
            })
    }

    return (
        <ListScreen
            title={<div>{t('noun.address')}</div>}
            right={
                <Button
                    primary
                    onClick={() => {
                        setAddNewOpened(true)
                    }}
                >
                    {t('strings.new_address')}
                </Button>
            }
        >
            <AddressTable
                items={addresses}
                editAction={(e) => sendEdit(e)}
                deleteAction={(e) => sendDelete(e)}
            />
            {isAddNewOpened && (
                <AddNewAddress
                    isOpen={isAddNewOpened}
                    onClose={() => setAddNewOpened(false)}
                    onSave={sendCreate}
                ></AddNewAddress>
            )}
        </ListScreen>
    )
}

export default withTranslation()(AddressScreen)
