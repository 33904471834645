import { ApiResponse } from '../models/api/ApiResponse'
import {
    CBLineItem,
    CBLineItemCreate,
    LineItemUnit,
} from '../models/CBLineItem'

import EnvSettings from '../models/settings/EnvSettings'
import configService from './configService'
import axios, { apiErrorParser } from './base/BaseApiService'

class LineItemService {
    readonly config: EnvSettings

    constructor(config: EnvSettings) {
        this.config = config
    }

    async get(): Promise<ApiResponse<CBLineItem[]>> {
        try {
            const response = await axios('/api/v1/lineItem')
            return response.data
        } catch (error: any) {
            throw new Error(apiErrorParser(error).errors[0].reason)
        }
    }

    async getByCompanyId(
        companyId: number | string
    ): Promise<ApiResponse<CBLineItem[]>> {
        try {
            const response = await axios(
                `/api/v1/company/${companyId}/lineItems`
            )
            return response.data
        } catch (error: any) {
            throw new Error(apiErrorParser(error).errors[0].reason)
        }
    }

    async edit(
        id: number | string,
        nameHr: string,
        unitPrice: number,
        currency: string,
        unit: LineItemUnit,
        nameEn?: string | undefined | null
    ) {
        const dto: CBLineItemCreate = {
            nameHr,
            nameEn,
            unitPrice,
            currency,
            unit,
        }

        try {
            const response = await axios.put(`/api/v1/lineItem/${id}`, dto)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }

    async delete(id: number): Promise<ApiResponse<Boolean>> {
        try {
            const response = await axios.delete(`/api/v1/lineItem/${id}`)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }

    async addNew(
        nameHr: string,
        unitPrice: number,
        currency: string,
        unit: LineItemUnit,
        nameEn?: string | undefined | null
    ): Promise<ApiResponse<CBLineItem>> {
        const dto: CBLineItemCreate = {
            nameHr,
            nameEn,
            unitPrice,
            currency,
            unit,
        }

        try {
            const response = await axios.post(`/api/v1/lineItem`, dto)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }
}

export default new LineItemService(configService.settings)
