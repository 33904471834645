import { ReactElement } from 'react'

export interface _ColumnDefinition {
    title?: string
    key?: string
    localizedTitle?: string
    field: string
    textAlign?: 'start' | 'end' | 'center' | undefined
    formatter?(e: any): string | ReactElement
}

export enum PowerGridAction {
    Edit = 1,
    Download,
    Delete,
}

export interface _ActionDefinition {
    actionType: PowerGridAction
    onClick?(e: any): void
}

export interface PowerGridConfig {
    dataSource: Array<any>
    key: (item: any) => number
    sortable?: Boolean
    pageable?: Boolean
    columns: Array<_ColumnDefinition>
    actions?: Array<_ActionDefinition>
}
