import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import configService from '../configService'
import EnvSettings from '../../models/settings/EnvSettings'
import TokenService from './TokenService'
import { TokenDto } from '../../models/LoginDto'
import { ApiResponse } from '../../models/api/ApiResponse'
import { ApiError } from '../../models/ApiErrorDto'

class BaseApiService {
    axiosInstance: AxiosInstance
    tokenService: typeof TokenService

    constructor(settings: EnvSettings, tokenService: typeof TokenService) {
        this.tokenService = tokenService
        this.axiosInstance = axios.create({
            baseURL: settings.apiRoot,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
    }

    configureRequestInterceptor(): BaseApiService {
        const tokenService = this.tokenService
        this.axiosInstance.interceptors.request.use(
            (config) => {
                const token = tokenService.getProfile()?.token?.cryptogram
                if (token) {
                    config.headers = config.headers ?? {}
                    config.headers['Authorization'] = `Bearer ${token}`
                }
                return config
            },
            (error) => {
                return Promise.reject(error)
            }
        )

        return this
    }

    configureReponseInterceptor(): BaseApiService {
        const instance = this.axiosInstance
        const tokenService = this.tokenService

        instance.interceptors.response.use(
            (res) => {
                return res
            },
            async (err) => {
                const originalConfig = err.config

                if (originalConfig.url !== '/api/v1/login' && err.response) {
                    // Access Token was expired
                    if (err.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true

                        try {
                            const loginInfo = tokenService.getProfile()

                            const rs = await instance.post<
                                TokenDto,
                                AxiosResponse<ApiResponse<TokenDto>>
                            >('/api/v1/login/refresh', loginInfo?.token)

                            if (loginInfo) {
                                loginInfo.token = rs.data.data as TokenDto
                                tokenService.updateProfile(loginInfo)
                            }

                            return instance(originalConfig)
                        } catch (error) {
                            tokenService.removeProfile()
                            window.location = '/'
                            return Promise.reject(error)
                        }
                    }
                }

                return Promise.reject(err)
            }
        )
        return this
    }

    axios(): AxiosInstance {
        return this.axiosInstance
    }
}

export default new BaseApiService(configService.settings, TokenService)
    .configureReponseInterceptor()
    .configureRequestInterceptor()
    .axios()

export function apiErrorParser(axiosResponse: AxiosError<ApiError>): ApiError {
    return axiosResponse.response?.data as ApiError
}
