import React from 'react'
import PowerMenuItem from './PowerMenuItem'

import './PowerMenu.scss'

interface MenuItemDef {
    image: string
    imageSelected: string
    text: string
    link: string
}

interface MenuProps {
    items?: Array<MenuItemDef>
}

function renderItems(props: React.PropsWithChildren<MenuProps>) {
    if (!props.items || props.items.length === 0) {
        return <div>{props.children}</div>
    }

    return props.items.map((item) => {
        return (
            <PowerMenuItem
                image={item.image}
                imageSelected={item.imageSelected}
                link={item.link}
                text={item.text}
            ></PowerMenuItem>
        )
    })
}

const PowerMenu: React.VFC<React.PropsWithChildren<MenuProps>> = (
    props: React.PropsWithChildren<MenuProps>
) => {
    return <ul className='power-menu'>{renderItems(props)}</ul>
}

export default PowerMenu
