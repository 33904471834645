import { ApiResponse } from '../models/api/ApiResponse'

import { Address, AddressCreate } from '../models/Address'
import EnvSettings from '../models/settings/EnvSettings'
import configService from './configService'
import axios, { apiErrorParser } from './base/BaseApiService'

class AddressService {
    readonly config: EnvSettings

    constructor(config: EnvSettings) {
        this.config = config
    }

    async getAll(): Promise<ApiResponse<Address[]>> {
        const response = await axios.get<ApiResponse<Address[]>>(
            '/api/v1/address'
        )

        return response.data
    }

    async create(dto: AddressCreate): Promise<ApiResponse<Address>> {
        try {
            const response = await axios.post(`/api/v1/address`, dto)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }

    async delete(id: number): Promise<ApiResponse<Boolean>> {
        try {
            const response = await axios.delete(`/api/v1/address/${id}`)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }
}

export default new AddressService(configService.settings)
