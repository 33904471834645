import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Segment } from 'semantic-ui-react'
import { Client } from '../../models/Client'

interface Props extends WithTranslation {
    client?: Client
}

const InvoiceClientRenderer: React.VFC<Props> = (props) => {
    const { t, client } = props

    if (!client) {
        return <></>
    }

    const zipCityState: string[] = []

    zipCityState.push(client.address.city)
    zipCityState.push(client.address.zip)
    if (client.address.state) {
        zipCityState.push(client.address.state)
    }

    return (
        <>
            <div
                style={{
                    fontSize: '0.9rem',
                    fontWeight: '700',
                    marginBottom: '0.21rem',
                }}
            >
                {t('strings.invoice_issued_by')}
            </div>
            <Segment style={{ marginTop: 0 }} secondary compact>
                <div>{client.name}</div>
                <div>{client.address.address1}</div>
                <div>{zipCityState.join(', ')}</div>
                <div>{client.address.country}</div>
                <br />
                {`${t('strings.tax_number')} #${client.pin}`}
            </Segment>
        </>
    )
}

export default withTranslation()(InvoiceClientRenderer)
