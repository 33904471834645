import React, { Component } from 'react'
import { BrowserRouter as Link } from 'react-router-dom'
import './SubView.css'

const SubmitView = () => {
    return (
        <div className='submittedView'>
            <div className='subHead'>
                <span>Invoices Submitted </span>

                <a className='clearedButton' href='/#'>
                    MARK AS CLEARED
                </a>
            </div>

            <div className='subInvoice'>
                <div className='dlMailPrint'>
                    <ul className='navigation'>
                        <li>
                            <Link to='/' className='dlAsPDF'>
                                {/* <img className="dashPic" src={} alt="" /> */}
                                <h1>ewuigfbiuwebnofc</h1>
                            </Link>
                        </li>
                        <br />
                        <li>
                            <Link to='/Invoices' className='sendMail'>
                                {/* <img className="invoicesPic" src={} alt="" /> */}
                                <h1>ewuigfbiuwebnofc</h1>
                            </Link>
                        </li>
                        <br />
                        <li>
                            <Link to='/Clients' className='printing'>
                                {/* <img className="clientPic" src={} alt="" /> */}
                                <h1>ewuigfbiuwebnofc</h1>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SubmitView
