import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Button, Loader, Modal, TransitionablePortal } from 'semantic-ui-react'
import PowerFlex from './layout/PowerFlex'

import './PowerPopup.scss'

interface Props extends WithTranslation {
    size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen'

    isOpen: boolean
    title: string
    isLoading?: boolean
    onClose(): void
    closeButtonText?: string
    actions?: React.ReactNode | undefined
}

const PowerPopup: React.FC<Props> = (props) => {
    const { t, size, isOpen, isLoading = false, onClose } = props

    const {
        title,
        closeButtonText = t('verb.close'),
        children,
        actions,
    } = props

    function close() {
        if (confirm(t('strings.exit'))) {
            onClose()
        }
    }

    return (
        <TransitionablePortal
            open={isOpen}
            transition={{ animation: 'scale', duration: 300 }}
        >
            <Modal size={size} open={true} dimmer='inverted'>
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content>{children}</Modal.Content>
                <Modal.Actions>
                    <PowerFlex
                        container
                        alignItems='flex-end'
                        justifyContent='flex-end'
                        gap={1}
                    >
                        <PowerFlex>
                            {isLoading && (
                                <Loader inverted inline indeterminate></Loader>
                            )}
                        </PowerFlex>
                        <PowerFlex>
                            {actions}
                            <Button negative onClick={close}>
                                {closeButtonText}
                            </Button>
                        </PowerFlex>
                    </PowerFlex>
                </Modal.Actions>
            </Modal>
        </TransitionablePortal>
    )
}

export default withTranslation()(PowerPopup)
