import React from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownItemProps } from 'semantic-ui-react'

interface Country {
    isoCode: string
    localizedName: string
}

export default function useCountries(): Country[] {
    const { i18n } = useTranslation()

    return require('localized-countries')(
        require('localized-countries/data/' + 'hr')
    )
        .array()
        .map((item: any) => {
            return {
                isoCode: item.code,
                localizedName: item.label,
            }
        })
}

export function useCountriesForDropdown(): DropdownItemProps[] {
    const countries = useCountries()

    return countries.map((item) => {
        return {
            key: item.isoCode,
            value: item.isoCode,
            text: item.localizedName,
            flag: item.isoCode.toLocaleLowerCase(),
        }
    })
}
