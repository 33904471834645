import React from 'react'
import TextInputForm from '../../common/core/form/TextInputForm'
import { SubmitHandler, UnpackNestedValue, useForm } from 'react-hook-form'
import { Button, Form } from 'semantic-ui-react'
interface FormFields {
    firstName: String
    lastName: String
    email: String
    phoneNumber: String
    address: String
}

const GeneralSettings: React.VFC = () => {
    const defaults: Partial<FormFields> = {}

    const {
        register,
        handleSubmit,
        formState: { isValid },
    } = useForm<FormFields>({
        mode: 'onChange',
        defaultValues: defaults,
    })

    const onSubmit: SubmitHandler<FormFields> = (
        deita: UnpackNestedValue<FormFields>
    ) => {
        alert(JSON.stringify(deita))
    }

    const onError = (deita: any) => {}

    return (
        <Form
            className='form-container'
            onSubmit={handleSubmit(onSubmit, onError)}
        >
            <div className='form-element-container'>
                <Form.Group widths='equal'>
                    <TextInputForm
                        name='firstName'
                        label='First Name'
                        register={register}
                        required
                    />
                    <TextInputForm
                        name='lastName'
                        label='Last Name'
                        register={register}
                        required
                    />
                    <TextInputForm
                        name='email'
                        label='Email'
                        register={register}
                        required
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <TextInputForm
                        name='address'
                        label='Address'
                        register={register}
                        required
                    />
                    <TextInputForm
                        name='phoneNumber'
                        label='Phone Number'
                        register={register}
                        required
                    />
                </Form.Group>
                <div className='form-button-container'>
                    <Button
                        disabled={!isValid}
                        primary
                        className='wipe-margin'
                        type='submit'
                    >
                        Apply
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default GeneralSettings
