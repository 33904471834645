import React from 'react'

interface Props {
    foot: any
}

const CompanyInfo: React.VFC<Props> = (props) => {
    const { foot } = props

    return <section className='footer'>{foot.footer}</section>
}

export default CompanyInfo
