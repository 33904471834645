import React from 'react'
import { NavLink } from 'react-router-dom'

import './PowerMenu.scss'

interface MenuItemProps {
    image: string
    imageSelected: string
    text: string
    link: string
}

function PowerMenuItem(props: MenuItemProps) {
    const [isActive, setActive] = React.useState(false)

    const selectedClass = isActive ? 'selected' : ''
    const navLinkImage = isActive ? props.imageSelected : props.image

    return (
        <li className={'power-menu-item ' + selectedClass}>
            <NavLink
                to={props.link}
                className={({ isActive }) => {
                    setActive(isActive)
                    return ''
                }}
            >
                <img className='power-menu-image' src={navLinkImage} alt='' />
                {props.text}
            </NavLink>
        </li>
    )
}
export default PowerMenuItem
