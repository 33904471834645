import React from 'react'
import useSWR, { KeyedMutator } from 'swr'
import invoiceService from '../services/invoiceService'
import { ApiFacetResult, FacetedData } from '../models/api/FacetResponse'
import { InvoiceListDto } from '../models/invoice/InvoiceDto'

export default function useInvoices(): [any[], () => void] {
    const { data, mutate } = useSWR('invoices', invoiceService.getAll)

    return React.useMemo(() => {
        const invoices = data?.data || []

        return [invoices, mutate]
    }, [data])
}

export function useInvoicesForList(year?: string): [FacetedData<InvoiceListDto[]> | undefined, () => void] {
    const fetcher = () => {
        return invoiceService.getListAll(year)
    }

    const { data, mutate } = useSWR('invoices/list', fetcher)

    return React.useMemo(() => {
        const invoices = data?.data 

        return [invoices, mutate]
    }, [data])
}

export function useInvoicePrintById(id: number, language: string): any {
    const fetcher = () => {
        return invoiceService.getPrintById(id, language)
    }

    const { data } = useSWR(`invoices/${id}/${language}`, fetcher)

    return React.useMemo(() => {
        const invoice = data?.data

        return invoice
    }, [data])
}

export function useInvoiceForEdit(id: number) {
    const fetcher = () => {
        return invoiceService.get(id)
    }

    const { data, mutate: mutateInvoice } = useSWR(`invoices/${id}`, fetcher)

    return React.useMemo(() => {
        const invoice = data?.data

        return {
            invoice,
            mutateInvoice,
        }
    }, [data])
}
