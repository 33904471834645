import React from 'react'
import { DropdownItemProps } from 'semantic-ui-react'
import useSWR from 'swr'
import { useToast } from '../common/ui/toast/ToastProvider'
import { CBLineItem, LineItemUnit } from '../models/CBLineItem'
import lineItemService from '../services/LineItemService'

function useLineItems(companyId?: number | undefined): CBLineItem[] {
    const [lineItems, setLineItems] = React.useState<CBLineItem[]>([])

    const toast = useToast()

    React.useEffect(() => {
        const promise = companyId
            ? lineItemService.getByCompanyId(companyId)
            : lineItemService.get()
        promise
            .then((r) => {
                setLineItems(r.data)
            })
            .catch((err) => {
                toast.showError(err)
            })
    }, [companyId])

    return React.useMemo(() => {
        return lineItems
    }, [lineItems, companyId])
}

export function useLineItemsSwr(companyId?: number | undefined) {
    const params = companyId ? `lineItems/${companyId}` : 'lineItems'

    const { data: mehData, mutate } = useSWR(params, () => {
        return companyId
            ? lineItemService.getByCompanyId(companyId)
            : lineItemService.get()
    })

    return {
        data: mehData?.data || [],
        mutate,
    }
}

export function dropdownLineItems(items: CBLineItem[]): DropdownItemProps[] {
    const dropdownItems = items.map((item) => {
        const price = `${item.unitPrice}${item.currency.code}`
        var unit = 'h'
        switch (item.unit) {
            case LineItemUnit.SINGLE:
                unit = 'pc'
                break
            default:
                break
        }

        return {
            key: item.id,
            value: item.id,
            text: `${item.nameHr} ${price}/${unit}`,
        }
    })

    return React.useMemo(() => dropdownItems, [dropdownItems])
}

export default useLineItems
