import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import '../../../../src/common/app/styling/Forms.scss'
import Screen from '../../../common/ui/screen/Screen'
import './NewInvoice.scss'
import { useNavigate, useParams } from 'react-router-dom'
import UpdateInvoice from './UpdateInvoice'
import { useInvoiceForEdit } from '../../../hooks/useInvoices'

interface Props extends WithTranslation {}

const UpdateInvoiceScreen: React.VFC<Props> = (props) => {
    const { t } = props
    const { invoiceId } = useParams() as any
    const navigate = useNavigate()

    const { invoice, mutateInvoice } = useInvoiceForEdit(invoiceId)

    if (!invoice) {
        return <></>
    }

    return (
        <Screen title='Invoices > Edit Invoice'>
            <UpdateInvoice
                existingInvoice={invoice}
                onSuccess={() => {
                    mutateInvoice()
                    navigate(-1)
                }}
            ></UpdateInvoice>
        </Screen>
    )
}

export default withTranslation()(UpdateInvoiceScreen)
