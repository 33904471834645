import React from 'react'
import { Address } from '../../models/Address'
import PowerGrid from '../../common/ui/powerGrid/PowerGrid'
import { PowerGridAction, PowerGridConfig } from './powerGrid/PowerGridConfig'

interface _Props {
    items: Array<Address>
    editAction?(address: Address): any
    deleteAction?(adress: Address): any
}

const AddressTable = (props: _Props) => {
    const { items } = props

    const config: PowerGridConfig = {
        dataSource: items,
        key: (item) => item.id,
        columns: [
            { field: 'id', title: 'Id' },
            { field: 'address1', title: 'Address 1' },
            { field: 'address2', title: 'Address 2' },
            { field: 'city', title: 'City' },
            { field: 'zip', title: 'Zip' },
            { field: 'state', title: 'State' },
            { field: 'country', title: 'Country' },
        ],
        actions: [
            {
                actionType: PowerGridAction.Edit,
                onClick: (item) => {
                    props.editAction?.(item)
                },
            },
            {
                actionType: PowerGridAction.Delete,
                onClick: (item) => {
                    props.deleteAction?.(item)
                },
            },
        ],
    }

    return <PowerGrid config={config}></PowerGrid>
}

export default AddressTable
