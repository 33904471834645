interface Contract {
    id: number
    type: string
    paymentDueDays: number
}

export enum ContractType {
    SOW = 'SOW',
    RENT = 'RENT',
    SUBCONTRACT = 'SUBCONTRACT',
}

export type { Contract }
