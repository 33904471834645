import React, { Component } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

import InvoicesTable from '../../common/ui/InvoicesTable'
import ListScreen from '../../common/ui/screen/ListScreen'
import CBButton from '../../common/ui/CBLinkButton'

interface _Props extends WithTranslation {}

class Invoices extends Component<_Props, {}> {
    render() {
        const { t } = this.props

        return (
            <ListScreen
                title={t('noun.invoices')}
                right={
                    <CBButton
                        href='/Invoices/NewInvoice'
                        title={t('strings.new_invoice')}
                    ></CBButton>
                }
            >
                <InvoicesTable />
            </ListScreen>
        )
    }
}
export default withTranslation()(Invoices)
