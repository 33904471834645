import { he } from 'date-fns/locale'
import { ApiResponse } from '../models/api/ApiResponse'
import { CreateInvoiceDto } from '../models/invoice/CreateInvoiceDto'
import { InvoiceDataDto } from '../models/invoice/InvoiceDataDto'
import { InvoiceDto, InvoiceListDto } from '../models/invoice/InvoiceDto'
import { InvoiceScreenDto } from '../models/invoice/InvoiceScreenDto'
import { UpdateInvoiceDto } from '../models/invoice/UpdateInvoiceDto'

import EnvSettings from '../models/settings/EnvSettings'
import axios, { apiErrorParser } from './base/BaseApiService'
import configService from './configService'
import { ApiFacetResult } from '../models/api/FacetResponse'
import { string } from 'yup'

class InvoiceService {
    readonly config: EnvSettings

    constructor(config: EnvSettings) {
        this.config = config
    }

    async getPrintById(
        id: number,
        language?: string
    ): Promise<ApiResponse<any>> {
        try {
            var headers: any = {}

            if (language) {
                headers['Accept-Language'] = language
            }
            const response = await axios.get(`/screen/v1/invoice/print/${id}`, {
                headers: headers,
            })
            return await response.data
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async get(
        invoiceId: number | string
    ): Promise<ApiResponse<InvoiceDataDto>> {
        try {
            const response = await axios.get(`/api/v1/invoice/${invoiceId}`)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }

    async getAll(): Promise<ApiResponse<InvoiceDto[]>> {
        try {
            const response = await axios.get('/api/v1/invoice')
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getListAll(year?: string): Promise<ApiFacetResult<InvoiceListDto[]>>
    {
        var params = !!year ? {year: year} : {}

        try {
            const response = await axios.get('/api/v1/invoice/list', {
                params
            })
            return response.data
        } catch (error) {
            throw error
        }
    }

    async delete(invoiceId: number | string): Promise<any> {
        try {
            const response = await axios.delete(`/api/v1/invoice/${invoiceId}`)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }

    async newInvoiceDataPackage(): Promise<InvoiceScreenDto> {
        const responst = await axios.get<ApiResponse<InvoiceScreenDto>>(
            '/screen/v1/invoice/new'
        )
        return responst.data.data
    }

    async createNewInvoice(dto: CreateInvoiceDto): Promise<ApiResponse<any>> {
        try {
            const response = await axios.post(`/api/v1/invoice`, dto)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }

    async updateInvoice(
        id: number | string,
        dto: UpdateInvoiceDto
    ): Promise<ApiResponse<any>> {
        try {
            const response = await axios.put(`/api/v1/invoice/${id}`, dto)
            return response.data
        } catch (err: any) {
            const error = apiErrorParser(err)
            throw new Error(error.errors[0].reason)
        }
    }
}

export default new InvoiceService(configService.settings)
