import { yupResolver } from '@hookform/resolvers/yup'
import { DateTime } from 'luxon'
import React from 'react'
import {
    UnpackNestedValue,
    useFieldArray,
    useForm,
    useWatch,
} from 'react-hook-form'
import { WithTranslation, withTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    Button,
    Dimmer,
    Dropdown,
    Form,
    Grid,
    Icon,
    Loader,
    Segment,
} from 'semantic-ui-react'
import * as Yup from 'yup'
import '../../../../src/common/app/styling/Forms.scss'
import AddEditLineItem from '../../../common/app/fragments/AddEditNewLineItem'
import PowerDate from '../../../common/core/form/PowerDate'
import PowerSelect from '../../../common/core/form/PowerSelect'
import TextInputForm from '../../../common/core/form/TextInputForm'
import InvoiceClientRenderer from '../../../common/ui/InvoiceClientRenderer'
import PowerFlex from '../../../common/ui/layout/PowerFlex'
import Screen from '../../../common/ui/screen/Screen'
import { ToastType } from '../../../common/ui/toast/ToastItem'
import { useToast } from '../../../common/ui/toast/ToastProvider'
import useCompanies, {
    useCompaniesForDropdown,
} from '../../../hooks/useCompanies'
import { dropdownLineItems, useLineItemsSwr } from '../../../hooks/useLineItems'
import useNewInvoiceDataPackage from '../../../hooks/useNewInvoiceDataPackage'
import { CBLineItemCreate, LineItemUnit } from '../../../models/CBLineItem'
import { ContractType } from '../../../models/Contract'
import { CreateInvoiceDto } from '../../../models/invoice/CreateInvoiceDto'
import { PaymentMethods } from '../../../models/PaymentMethods'
import invoiceService from '../../../services/invoiceService'
import lineItemService from '../../../services/LineItemService'
import './NewInvoice.scss'
import { InvoiceLineItem } from './NewInvoiceModels'
import SubtotalRenderer from './SubtotalRenderer'

interface FormFields {
    clientId: number
    invoiceNumber: string
    contract?: number | null
    billingDate: Date
    deliveryDate: Date
    paymentDueDate: Date
    paymentMethod: string
    operator: string
    extraInfo?: string
    referenceNumber?: string
    items: InvoiceLineItem[]
}

declare module 'yup' {
    // tslint:disable-next-line
    interface ArraySchema<T> {
        unique(message: any, mapper?: (a: T) => T): ArraySchema<T>
    }
}

const formatDateForApi = (date: Date) => {
    const format = 'yyyy-MM-dd'
    return DateTime.fromJSDate(date).toFormat(format)
}

const lastWorkingDayInPreviousMonth = () => {
    const now = DateTime.now()
    const startOfMonth = DateTime.local(now.year, now.month, 1)
    var lastDay = startOfMonth.minus({ days: 1 })

    const weekdays = [1, 2, 3, 4, 5]
    while (!weekdays.includes(lastDay.weekday)) {
        lastDay = lastDay.minus({ days: 1 })
    }

    return lastDay
}

const firstDay = () => {
    const now = DateTime.now()
    var startOfMonth = DateTime.local(now.year, now.month, 1)

    const weekdays = [1, 2, 3, 4, 5]
    while (!weekdays.includes(startOfMonth.weekday)) {
        startOfMonth = startOfMonth.plus({ days: 1 })
    }

    return startOfMonth
}

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a: any) => a) {
    return this.test('unique', message, function (list) {
        if (!list) {
            return true
        }
        return list.length === new Set(list.map(mapper)).size
    })
})

const validationSchema = Yup.object().shape({
    clientId: Yup.number().required(),
    invoiceNumber: Yup.string().required(),
    operator: Yup.string().required(),
    billingDate: Yup.date().required(),
    deliveryDate: Yup.date()
        .required()
        .when('billingDate', (billingDate: Date, yup) => {
            return billingDate && yup.min(billingDate)
        }),
    paymentDueDate: Yup.date().required(),
    paymentMethod: Yup.string().required(),
    contract: Yup.string().optional(),
    referenceNumber: Yup.string().optional(),
    extraInfo: Yup.string().optional(),
    items: Yup.array()
        .of(
            Yup.object().shape({
                protoId: Yup.number().required(),
                quantity: Yup.number().required(),
                description: Yup.string().required(),
                descriptionEn: Yup.string().optional(),
                price: Yup.number().required(),
                unit: Yup.string().required(),
                currency: Yup.string().required(),
                discountPercent: Yup.number().min(0).max(99.99).notRequired(),
            })
        )
        .required()
        .unique('Only unique items can be added', (e: any) => e.protoId)
        .min(1, 'At least 1 item required'),
})

interface Props extends WithTranslation {}

const NewInvoice: React.VFC<Props> = (props) => {
    const { t } = props

    const defaultValues: Partial<FormFields> = {}

    const [isAddNewOpened, setAddNewOpened] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const companiesDropdown = useCompaniesForDropdown()
    const { data: companies } = useCompanies()
    const navigate = useNavigate()
    const toast = useToast()

    const [dataPackage] = useNewInvoiceDataPackage()

    const { control, register, handleSubmit, setValue, getValues, formState } =
        useForm<FormFields>({
            resolver: yupResolver(validationSchema),
            defaultValues,
        })

    if (dataPackage) {
        setValue('operator', dataPackage.operator.operatorName)
        setValue('invoiceNumber', dataPackage.nextInvoiceNumber.formatted)
        setValue('paymentMethod', PaymentMethods.WIRE)
    }

    const { errors } = formState
    const [selectedItemId, setSelectedItemId] = React.useState<number>()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'items',
    })

    const currentLineItems =
        (useWatch<FormFields>({
            control,
            name: 'items',
        }) as InvoiceLineItem[]) || []

    const clientId = useWatch<FormFields>({
        control,
        name: 'clientId',
    }) as number

    const contractId = useWatch<FormFields>({
        control,
        name: 'contract',
    }) as number | null

    const contractsDropdown =
        companies
            .find((e) => e.id == clientId)
            ?.contracts?.map((e) => {
                return {
                    key: e.id,
                    text: `${e.type} - ${e.paymentDueDays}`,
                    value: e.id,
                }
            }) ?? []

    if (contractsDropdown.length == 0) {
        if (contractId) {
            setValue('contract', null)
        }
    }

    const { data: lineItems, mutate: mutateLineItems } =
        useLineItemsSwr(clientId)
    const selectLineItems = dropdownLineItems(lineItems)
    const invoiceCurrency = lineItems.map((e) => e.currency)[0]?.symbol ?? 'USD'

    if (contractId) {
        const currentContract = companies
            .find((e) => e.id == clientId)
            ?.contracts?.find((e) => e.id === contractId)

        if (currentContract) {
            var invoiceDate: DateTime = DateTime.now().set({
                hour: 12,
                minute: 0,
                second: 0,
                millisecond: 0,
            })
            switch (currentContract.type) {
                case ContractType.RENT:
                    invoiceDate = firstDay()
                    setValue(
                        'paymentDueDate',
                        invoiceDate
                            .set({ day: currentContract.paymentDueDays })
                            .toJSDate()
                    )
                    break
                default:
                    invoiceDate = lastWorkingDayInPreviousMonth()
                    setValue(
                        'paymentDueDate',
                        invoiceDate
                            .plus({ days: currentContract.paymentDueDays })
                            .toJSDate()
                    )
                    break
            }

            const currentBillingDate = DateTime.fromJSDate(
                getValues('billingDate')
            )
            const currentDeliveryDate = DateTime.fromJSDate(
                getValues('deliveryDate')
            )

            if (
                !currentBillingDate ||
                !invoiceDate.equals(currentBillingDate)
            ) {
                setValue('billingDate', invoiceDate.toJSDate())
            }

            if (
                !currentDeliveryDate ||
                !invoiceDate.equals(currentBillingDate)
            ) {
                setValue('deliveryDate', invoiceDate.toJSDate())
            }
        }
    }

    const billingDate = useWatch<FormFields>({
        control,
        name: 'billingDate',
    }) as Date | null

    if (billingDate) {
        const deliveryDate = getValues('deliveryDate')
        const invoiceDueDate = getValues('paymentDueDate')

        if (!deliveryDate || deliveryDate < billingDate) {
            setValue('deliveryDate', billingDate)
        }
        if (!invoiceDueDate || invoiceDueDate < billingDate) {
            setValue('paymentDueDate', billingDate)
        }
    }

    const onSubmit = async (data: UnpackNestedValue<FormFields>) => {
        const currentContract = companies
            .find((e) => e.id == data.clientId)
            ?.contracts?.find((e) => e.id === contractId)

        const query: CreateInvoiceDto = {
            paymentDueDate: formatDateForApi(data.paymentDueDate),
            billingDate: formatDateForApi(data.billingDate),
            deliveryDate: formatDateForApi(data.deliveryDate),
            paymentMethod: data.paymentMethod,
            referenceNumber: data.referenceNumber,
            extraInfo: data.extraInfo,
            clientId: data.clientId,
            contractType: currentContract?.type,
            contractDueDays: currentContract?.paymentDueDays,
            operatorId: dataPackage?.operator.id ?? 1,
            lineItems: data.items.map((item) => {
                return {
                    ...item,
                    id: item.protoId,
                }
            }),
        }

        setIsLoading(true)

        try {
            const result = await invoiceService.createNewInvoice(query)
            toast.showMessage({
                type: ToastType.SUCCESS,
                title: t('noun.invoice'),
                description: `${t('noun.success')}! Number: ${
                    result.data.number
                }`,
            })
            navigate(-1)
        } catch (err: any) {
            toast.showError(err.message)
        }

        setIsLoading(false)
    }

    const sendCreate = (it: CBLineItemCreate) => {
        lineItemService
            .addNew(it.nameHr, it.unitPrice, it.currency, it.unit, it.nameEn)
            .then((it) => {
                mutateLineItems()
                setAddNewOpened(false)
                const newLineItem = it.data
                toast.showMessage({
                    title: 'Kill bills!',
                    description: 'New Line Item created:' + newLineItem.id,
                    type: ToastType.SUCCESS,
                })
                append({
                    currency: newLineItem.currency.code,
                    description: newLineItem.nameHr,
                    descriptionEn: newLineItem.nameEn,
                    protoId: newLineItem.id,
                    price: newLineItem.unitPrice,
                    unit: newLineItem.unit == LineItemUnit.HOURLY ? 'h' : 'pc',
                    discountPercent: 0.0,
                    quantity: 1,
                })
            })
    }

    const onCreateItem = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()

        setAddNewOpened(true)
    }

    const onDropdownChanged = (e: any, formValues: any) => {
        setSelectedItemId(formValues.value)
    }

    const onAddNewItem = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()

        if (!selectedItemId) {
            return
        }

        const selectedLineItem = lineItems.filter(
            (item) => item.id === selectedItemId
        )[0]

        setSelectedItemId(0)

        if (!selectedLineItem) {
            return
        }

        if (currentLineItems.some((e) => e.protoId === selectedItemId)) {
            toast.showError('Item already exists in the list')
            return
        }

        append({
            currency: selectedLineItem.currency.code,
            description: selectedLineItem.nameHr,
            descriptionEn: selectedLineItem.nameEn,
            protoId: selectedItemId,
            price: selectedLineItem.unitPrice,
            unit: selectedLineItem.unit == LineItemUnit.HOURLY ? 'h' : 'pc',
            discountPercent: 0.0,
            quantity: 1,
        })
    }

    const onRemoveItem = (
        event: React.MouseEvent<HTMLButtonElement>,
        index: number
    ) => {
        event.stopPropagation()
        event.preventDefault()
        remove(index)
    }

    if (!dataPackage) {
        return <></>
    }

    console.log(errors)

    return (
        <Screen title='Invoices > New Invoice'>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Segment className='new-invoice-client-info'>
                    <Form.Group widths='equal'>
                        <PowerSelect
                            name='clientId'
                            label={t('noun.client')}
                            control={control}
                            fluid
                            placeholder={t('strings.select_client')}
                            required
                            options={companiesDropdown}
                            errors={errors}
                        />
                        <TextInputForm
                            name='invoiceNumber'
                            label={t('strings.invoice_number')}
                            required
                            disabled
                            register={register}
                            errors={errors}
                        ></TextInputForm>
                        <TextInputForm
                            disabled
                            name='operator'
                            label={t('strings.operator_name')}
                            register={register}
                        ></TextInputForm>
                    </Form.Group>
                    <Form.Group widths={3}>
                        <PowerSelect
                            name='contract'
                            label={t('noun.contract')}
                            clearable
                            control={control}
                            placeholder={t('strings.select_contract')}
                            options={contractsDropdown}
                            errors={errors}
                        ></PowerSelect>
                        <TextInputForm
                            name='extraInfo'
                            label={t('strings.internal_number')}
                            register={register}
                        ></TextInputForm>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <PowerDate
                            control={control}
                            label={t('strings.invoice_date')}
                            name='billingDate'
                            required
                            maxDate={new Date()}
                            errors={errors}
                        ></PowerDate>
                        <PowerDate
                            control={control}
                            label={t('strings.delivery_date')}
                            name='deliveryDate'
                            errors={errors}
                            maxDate={new Date()}
                            required
                        ></PowerDate>
                        <PowerDate
                            control={control}
                            label={t('strings.payment_due_date')}
                            name='paymentDueDate'
                            errors={errors}
                            required
                        ></PowerDate>
                    </Form.Group>
                    <Grid>
                        <Grid.Row stretched>
                            <Grid.Column width={5}>
                                <PowerSelect
                                    control={control}
                                    name='paymentMethod'
                                    label={t('strings.payment_method')}
                                    fluid
                                    required
                                    disabled
                                    errors={errors}
                                    options={dataPackage.paymentMethods.map(
                                        (item) => {
                                            return {
                                                key: item,
                                                value: item,
                                                text: t(
                                                    'enums.payment_type_' +
                                                        item.toLowerCase()
                                                ),
                                            }
                                        }
                                    )}
                                ></PowerSelect>
                                <TextInputForm
                                    name='referenceNumber'
                                    label={t('strings.reference_number')}
                                    required={invoiceCurrency === 'HRK'}
                                    register={register}
                                    errors={errors}
                                ></TextInputForm>
                            </Grid.Column>
                            <Grid.Column width={5}></Grid.Column>
                            <Grid.Column width={6}>
                                <InvoiceClientRenderer
                                    client={dataPackage.me}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <PowerFlex
                    container
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <PowerFlex>
                        <h2>Line items</h2>
                    </PowerFlex>
                </PowerFlex>
                {fields.map((field: InvoiceLineItem, index) => {
                    const itemErrors = errors.items?.[index]
                    return (
                        <form
                            key={index}
                            onSubmit={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                        >
                            <Segment style={{ marginBottom: '2rem' }}>
                                <Grid verticalAlign='top' key={field.protoId}>
                                    <input
                                        style={{ display: 'none' }}
                                        value={field.protoId}
                                    ></input>
                                    <Grid.Row columns={16}>
                                        <Grid.Column width={7}>
                                            <TextInputForm
                                                name={`items.${index}.description`}
                                                label={t('noun.description')}
                                                register={register}
                                                errors={itemErrors}
                                            ></TextInputForm>
                                            <TextInputForm
                                                name={`items.${index}.descriptionEn`}
                                                label={t(
                                                    'strings.description_en'
                                                )}
                                                register={register}
                                                errors={itemErrors}
                                            ></TextInputForm>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <TextInputForm
                                                name={`items.${index}.quantity`}
                                                label={t('noun.qty')}
                                                errors={itemErrors}
                                                rightLabel={{
                                                    basic: true,
                                                    content: field.unit,
                                                }}
                                                register={register}
                                            ></TextInputForm>
                                            <TextInputForm
                                                name={`items.${index}.price`}
                                                label={t('strings.unit_price')}
                                                register={register}
                                                rightLabel={{
                                                    basic: true,
                                                    content: field.currency,
                                                }}
                                                errors={itemErrors}
                                            ></TextInputForm>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <TextInputForm
                                                name={`items.${index}.discountPercent`}
                                                label={t(
                                                    'strings.discount_percent'
                                                )}
                                                register={register}
                                                rightLabel={{
                                                    basic: true,
                                                    content: '%',
                                                }}
                                                errors={itemErrors}
                                            ></TextInputForm>
                                            <SubtotalRenderer
                                                id={field.protoId}
                                                items={currentLineItems}
                                            ></SubtotalRenderer>
                                        </Grid.Column>
                                        <Grid.Column width={1}>
                                            <Form.Button
                                                icon
                                                color='red'
                                                fluid
                                                style={{
                                                    marginTop: '1.69rem',
                                                }}
                                                onClick={(event) =>
                                                    onRemoveItem(event, index)
                                                }
                                            >
                                                <Icon name='trash alternate'></Icon>
                                            </Form.Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </form>
                    )
                })}
                <Segment>
                    <form>
                        <Grid verticalAlign='bottom'>
                            <Grid.Column computer={10}>
                                <Dropdown
                                    selection
                                    search
                                    fluid
                                    value={selectedItemId}
                                    placeholder={t('strings.type_line_items')}
                                    options={selectLineItems}
                                    onChange={onDropdownChanged}
                                ></Dropdown>
                            </Grid.Column>
                            <Grid.Column computer={6}>
                                <Button
                                    primary
                                    onClick={(event) => onAddNewItem(event)}
                                >
                                    {t('strings.add_item')}
                                </Button>
                                <Button
                                    secondary
                                    onClick={(event) => onCreateItem(event)}
                                >
                                    {t('strings.create_new_item')}
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </form>
                </Segment>
                <PowerFlex container justifyContent='flex-end'>
                    <h3>
                        Total{' '}
                        {currentLineItems.reduce((acc, e) => {
                            return (
                                acc +
                                e.quantity *
                                    e.price *
                                    (1 - (e.discountPercent || 0) / 100)
                            )
                        }, 0)}{' '}
                        {invoiceCurrency}
                    </h3>
                </PowerFlex>
                <Button primary disabled={isLoading}>
                    Create Invoice
                </Button>
                <Dimmer active={isLoading} inverted>
                    <Loader inline></Loader>
                </Dimmer>
            </Form>
            {isAddNewOpened && (
                <AddEditLineItem
                    isOpen={isAddNewOpened}
                    isEnglishRequired={invoiceCurrency !== 'HRK'}
                    onClose={() => setAddNewOpened(false)}
                    onSaveOrEdit={sendCreate}
                ></AddEditLineItem>
            )}
        </Screen>
    )
}

export default withTranslation()(NewInvoice)
