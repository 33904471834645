import React from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Icon } from 'semantic-ui-react'

interface Props {
    name: string
    control: Control<any>

    label?: string
    required?: boolean
    showToday?: boolean
    minDate?: Date
    maxDate?: Date
    errors?: FieldErrors
    disabled?: boolean
    clearable?: boolean
}

const PowerDate: React.FC<Props> = (props) => {
    const {
        name,
        control,
        required = false,
        showToday = false,
        errors,
        maxDate,
        minDate,
        disabled,
        clearable = false,
        label,
    } = props

    return (
        <Controller
            control={control}
            name={name}
            rules={{ required: required }}
            render={({ field }) => {
                return (
                    <SemanticDatepicker
                        value={field.value}
                        label={label}
                        onChange={(e, elem) => {
                            field.onChange(elem.value)
                        }}
                        showToday={showToday}
                        required={required}
                        datePickerOnly
                        minDate={minDate}
                        clearOnSameDateClick={false}
                        disabled={disabled}
                        maxDate={maxDate}
                        clearable={clearable}
                        icon={
                            <Icon
                                size='large'
                                name='calendar alternate outline'
                            ></Icon>
                        }
                        error={errors && errors[name]}
                    ></SemanticDatepicker>
                )
            }}
        ></Controller>
    )
}

export default PowerDate
