import React, { useEffect } from 'react'
import { LoginReponseDto } from '../models/LoginDto'
import TokenService from '../services/base/TokenService'

export default function useAuthTokens() {
    var token: LoginReponseDto | undefined
    if (TokenService.getProfile()) {
        token = TokenService.getProfile()
    }

    const [tokens, setTokens] = React.useState(token)

    useEffect(() => {
        if (tokens) {
            TokenService.updateProfile(tokens)
        } else {
            TokenService.removeProfile()
        }
    }, [tokens])

    return [tokens, setTokens]
}
