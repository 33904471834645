import React, { useState } from 'react'
import LogoPic from '../../common/images/logo_svg_2.svg'
import loginService from '../../services/loginService'

import { Button, Form, Grid, Header, Segment, Image } from 'semantic-ui-react'

import './Login.scss'

export default function LoginForm(props) {
    const { onSetToken } = props
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    function validateForm() {
        return email.length > 0 && password.length > 0
    }

    async function handleSubmit(event) {
        loginService
            .login(email, password)
            .then((result) => {
                onSetToken(result.data)
            })
            .catch((err) => {
                alert(err.toString())
            })
    }

    return (
        <Grid textAlign='center' verticalAlign='middle' className='login-page'>
            <Grid.Column className='authorization'>
                <Image src={LogoPic} />
                <Header as='h2' color='blue' textAlign='center'>
                    Log-in to your account
                </Header>
                <Form size='large' onSubmit={handleSubmit}>
                    <Segment>
                        <Form.Input
                            fluid
                            icon='user'
                            iconPosition='left'
                            placeholder='E-mail address'
                            onChange={(e) => setEmail(e.target.value)}
                            type='email'
                        />
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Password'
                            type='password'
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <Button
                            color='blue'
                            fluid
                            size='large'
                            type='submit'
                            disabled={!validateForm()}
                        >
                            Login
                        </Button>
                    </Segment>
                </Form>
            </Grid.Column>
        </Grid>
    )
}
