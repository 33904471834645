import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Label, SemanticCOLORS } from 'semantic-ui-react'
import { InvoiceState } from '../../../models/invoice/InvoiceState'

interface Props extends WithTranslation {
    state: InvoiceState
}

const InvoiceStateRenderer: React.VFC<Props> = (props) => {
    const { t, state } = props

    var color: SemanticCOLORS = 'grey'
    var translationText: string = 'enums.invoice_state_' + state.toLowerCase()
    switch (state) {
        case InvoiceState.CLEARED:
            color = 'green'
            break
        case InvoiceState.DRAFT:
            color = 'grey'
            break
        case InvoiceState.PUBLISHED:
            color = 'yellow'
            break
        default:
            translationText = 'enums.unknown'
            break
    }

    return <Label color={color}>{t(translationText)}</Label>
}

export default withTranslation()(InvoiceStateRenderer)
