{
    "countables": {
        "hours_few": "{{count}} h",
        "hours_one": "{{count}} h",
        "hours_other": "{{count}} h",
        "pc_few": "{{count}} pcs",
        "pc_one": "{{count}} pc",
        "pc_other": "{{count}} pcs"
    },
    "currency": {
        "eur": "Euro",
        "hrk": "Croatian Kuna",
        "usd": "US Dollar"
    },
    "enums": {
        "invoice_state_cleared": "Cleared",
        "invoice_state_draft": "Draft",
        "invoice_state_published": "Published",
        "payment_type_advance": "Advance",
        "payment_type_cash": "Cash",
        "payment_type_wire": "Wire",
        "unknown": "Unknown"
    },
    "errors": {
        "generic_error": "An error happened. Call Bruno!",
        "max": "Maximum supported value exceeded",
        "maxLength": "Exceeded maximum length",
        "min": "Requires minimal Value",
        "minLength": "Minimal length required",
        "pattern": "Need to match pattern",
        "required": "This field is required",
        "unable_to_create_client": "Unable to create Client!",
        "unable_to_edit_client": "Unable to edit Client!"
    },
    "noun": {
        "action": "Action",
        "address": "Address",
        "amount": "Amount",
        "city": "City",
        "client": "Client",
        "clients": "Clients",
        "contact": "Contact",
        "contract": "Contract",
        "contracts": "Contracts",
        "country": "Country",
        "currency": "Currency",
        "dashboard": "Dashboard",
        "date": "Date",
        "description": "Description",
        "discount": "Discount",
        "draft": "Draft",
        "drafts": "Drafts",
        "duration": "Duration",
        "general": "General",
        "homepage": "Homepage",
        "iban": "IBAN",
        "invoice": "Invoice",
        "invoices": "Invoices",
        "link": "Link",
        "loading": "Loading",
        "month": "Month",
        "name": "Name",
        "note": "Note",
        "number": "#:",
        "operator": "Operator",
        "piece": "Piece",
        "qty": "Qty#",
        "rate": "Rate",
        "save": "Save",
        "search": "Search",
        "settings": "Settings",
        "state": "State",
        "status": "Status",
        "subtotal": "Subtotal",
        "success": "Success",
        "summary": "Summary",
        "swift": "SWIFT",
        "total": "Total",
        "unit": "Unit",
        "user": "User",
        "users": "Users",
        "vat": "VAT",
        "year": "Year"
    },
    "strings": {
        "about": "About",
        "add_item": "Add item",
        "address2": "Floor, Apt",
        "amount_due": "Amount due",
        "bill_date": "on the billing date",
        "billing_date": "Billing date",
        "billing_period": "Billing period",
        "client_created": "Client created",
        "client_deleted": "Client has been deleted!",
        "client_updated": "Client successfuly updated",
        "company_type": "Company type",
        "contactNumber": "Contact number",
        "contact_number": "Contact number",
        "create_new": "Create new",
        "create_new_item": "Create new item",
        "cro_exc_rate": "Croatian National Bank's (HNB) Middle Exchange rate of",
        "croatian_name": "Croatian name",
        "delivery_date": "Delivery date",
        "description_en": "Description (English)",
        "discount_percent": "Discount (%)",
        "e_mail_address": "E-mail address",
        "english_name": "English name",
        "exchange_rate": "HRK - exchange rate",
        "exit": "Do you want to exit",
        "hourly_rate": "Hourly rate",
        "internal_number": "Internal #: ",
        "invoice_amount": "Invoice amount",
        "invoice_date": "Date",
        "invoice_issued_by": "Invoice issued by",
        "invoice_number": "Invoice number",
        "invoice_raised": "Invoice raised",
        "invoice_status": "Invoice status",
        "issued_by": "Invoice issued by",
        "line_items": "Line Items",
        "monthly_trend": "Monthly trend",
        "nearby": "Nearby",
        "nearby_result": "Nearby result",
        "new_address": "New Address",
        "new_client": "New client",
        "new_invoice": "New invoice",
        "new_line_item": "New line item",
        "operator_name": "Operator",
        "overdue_payments": "Overdue payments",
        "payment_details": "Payment details",
        "payment_due_date": "Payment due date",
        "payment_method": "Payment method",
        "payment_received": "Payment received",
        "please_fill": "Please fill",
        "previous_month": "Previous month",
        "reference_number": "Reference number",
        "related_information": "Related information",
        "rent_contract": "Rent agreement",
        "see_more_options": "See more options",
        "select_client": "Select client",
        "select_contract": "Select contract",
        "settlement_notice": "As per Statement of Work, the settlement date is up to 30 days after the billing date",
        "sow_contract": "Statement of Work",
        "station_number": "Station number",
        "subcontract": "Subcontract",
        "tax_notice": "Service not taxable by Paragraph 1 of Section 17 of Croatian VAT Law",
        "tax_number": "TIN",
        "top_client": "Top client",
        "type_line_items": "Type to select line item...",
        "unit_price": "Unit Price",
        "update": "Update existing",
        "update_client": "Update client",
        "update_existing": "Update existing",
        "use_existing_addr": "Use existing address?",
        "vat_number": "VAT number",
        "zip_code": "ZIP Code"
    },
    "verb": {
        "cleared": "Cleared",
        "close": "Close",
        "create": "Create",
        "created": "Created",
        "delete": "Delete",
        "deleted": "Deleted",
        "edit": "Edit",
        "filter_by": "Filter",
        "login": "Log In",
        "logout": "Log Out",
        "order_by": "Order by",
        "register": "Register",
        "signup": "Sign Up",
        "submitted": "Submitted",
        "to": "To",
        "updated": "Updated"
    }
}
