import { LoginReponseDto } from '../../models/LoginDto'

const TokenKey: string = 'codebase_token-key'

class TokenService {
    storage: Storage

    constructor() {
        this.storage = localStorage
    }

    getProfile(): LoginReponseDto | undefined {
        const r = this.storage.getItem(TokenKey)
        if (r) {
            return JSON.parse(r)
        }
    }

    updateProfile(token: LoginReponseDto) {
        this.storage.setItem(TokenKey, JSON.stringify(token))
    }

    removeProfile() {
        localStorage.removeItem(TokenKey)
    }
}

export default new TokenService()
